import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import { Loading } from "@evertrue/et-components";
import LoginSource from "apps/linkedin/linkedin-login-source";
import SafeRedirect from "../../components/safe-redirect";

const wasCancelled = ({ error }) => {
  return error === "access_denied" || error === "user_cancelled_login";
};

export default createComponent("LoginProcess", {
  propTypes: {
    app: PropTypes.string,
    params: PropTypes.object,
  },

  componentDidMount() {
    if (!wasCancelled(this.props.params)) {
      LoginSource.authenticateWithLinkedIn(this.props.params);
    }
  },

  render() {
    const { params, app } = this.props;

    if (wasCancelled(params)) {
      return <SafeRedirect to={`/${app}/login?li_error=We're sorry! There was a problem authenticating via LinkedIn. Please try again.`} />;
    }

    return (
      <div className="login--wait-container">
        <div className="login--content">
          <div className="login--spinner">
            <Loading text="Please wait while we log you in..." />
          </div>
        </div>
      </div>
    );
  },
});
