import React from "react";
import Api from "utils/api";
import PropTypes from "prop-types";
import Methods from "utils/methods";
import { Button } from "@evertrue/et-components";
import OrgSource from "apps/org/org-source";

const EnterEmail = props => {
  const [email, setEmail] = React.useState();
  const [error, setError] = React.useState();

  const isEmailInSSO = () => {
    const encoded_email = encodeURIComponent(email.trim());
    return Api.get({
      url: `auth/identity_providers/search?email=${encoded_email}`,
    });
  };

  const submitEmail = () => {
    const is_valid = Methods.validateEmail(email);
    if (!is_valid) {
      setError("Please enter a valid email.");
    } else {
      isEmailInSSO()
        .then(data => {
          let has_sso = false;
          let is_internal = false;
          const first_org = data[0] || {};
          // if the email check has any organizations returned, then its an sso email
          if (data.length) {
            has_sso = true;
          }
          // if the user is "internal" that means its a super user and we may
          // want to allow different log in options (for testing purposes)
          if (first_org.internal_user) {
            is_internal = true;
          }

          // Fetch sso org to get the sso url
          Api.get({
            url: `auth/organizations/slug/${first_org.slug}`,
          })
            .then(resp => {
              if (resp.sso_method === "saml" && resp.identity_provider && resp.identity_provider.oauth2_authorize_url) {
                const auth_url = resp.identity_provider.oauth2_authorize_url;
                const token_data = resp.identity_provider.token_data;
                props.onSubmit({ email, has_sso, is_internal, url: auth_url });
                // need to set this sso data so the sso login controller will work because it still
                // relies on stores
                OrgSource.setSsoData(auth_url, token_data);
              } else {
                props.onSubmit({ email, has_sso: false, is_internal });
              }
            })
            .catch(() => {
              props.onSubmit({ email, has_sso: false, is_internal });
            });
        })
        .catch(error => {
          setError("There was a problem looking up the email. Please try again.");
        });
    }
  };

  return (
    <div className="enter-email">
      <form
        className="enter-email--body"
        onSubmit={e => {
          e.preventDefault();
          submitEmail();
        }}
      >
        <input
          className="enter-email--input fs-exclude-data"
          type="email"
          placeholder="Email Address"
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
        {error ? <div className="enter-email--error">{error}</div> : null}
        <Button className={`enter-email--button is-${props.app}`} buttonType="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

EnterEmail.propTypes = {
  onSubmit: PropTypes.func,
};

export default EnterEmail;
