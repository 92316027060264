import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import LinkedinLoginSource from "apps/linkedin/linkedin-login-source";

export default createComponent("AutoLinkedInLogin", {
  propTypes: {
    app: PropTypes.string.isRequired,
    slug: PropTypes.string,
  },

  componentDidMount() {
    LinkedinLoginSource.redirectToAuthUrl(this.props.app, this.props.slug);
  },

  render() {
    return null;
  },
});
