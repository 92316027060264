import React from "react";
import $ from "jquery";
import classNames from "classnames";

const STATUS_URL = "https://mnvchxx6cghv.statuspage.io/api/v2/status.json";

class StatusIndicator extends React.Component {
  state = {
    status: {},
  };

  componentWillMount() {
    this.fetchStatus();
  }

  fetchStatus() {
    $.ajax({
      url: STATUS_URL,
      type: "GET",
      success: data => {
        const { status } = data;
        this.setState({ status });
      },
    });
  }

  render() {
    const { status = {} } = this.state;

    return (
      <div className="status-indicator">
        <a href="https://evertrue.statuspage.io" target="_blank" rel="noopener noreferrer">
          <span className={classNames("status-indicator--dot", status.indicator)} />
          <span className="status-indicator--description">{status.description}</span>
        </a>
      </div>
    );
  }
}

export default StatusIndicator;
