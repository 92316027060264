import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import SessionSource from "apps/session/session-source";
import LinkedinLoginSource from "apps/linkedin/linkedin-login-source";
import LinkedinAssociateSource from "apps/linkedin/linkedin-associate-source";
import RegisterSource from "apps/register/register-source";
import AppRedirectSource from "apps/layout/app-redirect-source";
import MfaSource from "apps/mfa/mfa-source";
import SsoSource from "apps/sso/sso-source";
import ErrorLogger from "utils/error-logger";
import Utils from "utils/methods";

export default createStore("AppErrorStore", {
  getInitialState() {
    return { error: undefined };
  },

  registerActions() {
    this.on(SessionSource.actions.sessionError, this.respondToSessionError);
    this.on(SessionSource.actions.ssoLoginError, this.respondToSessionError);
    this.on(LinkedinLoginSource.actions.loginError, this.respondToError);
    this.on(RegisterSource.actions.registrationError, this.respondToError);
    this.on(SsoSource.actions.ssoError, this.respondToError);
    this.on(LinkedinAssociateSource.actions.linkedinError, this.respondToError);

    return this.on(AppRedirectSource.actions.clearError, () => this.setState({ error: undefined }));
  },

  respondToSessionError(method_name, extra) {
    const { xhr, headers } = extra;
    if (xhr && xhr.status === 403) {
      const response = Utils.jsonParse(xhr.responseText, {});
      ErrorLogger.warn("SessionError 403", { response });
      switch (response.error_code) {
        case "otp_required":
          return MfaSource.setRequired(response, headers);
        case "invalid_otp":
          return MfaSource.setError(response.message);
        default:
          if (_.contains(["mfa_locked", "mfa_required"], response.error_code)) {
            return this.setState({
              error: { message: response.message, status: xhr.status },
            });
          }
      }
    } else {
      return this.respondToError(method_name, extra);
    }
  },

  respondToError(method_name, extra) {
    const {
      xhr: { status = undefined, responseJSON: { message, error, error_code: code } = {} } = {},
      xhr,
      error_description,
      ...meta
    } = extra;
    const errorMessage = message || error || error_description || "error";

    ErrorLogger.captureRequest(`${method_name} - ${errorMessage}`, xhr, meta);
    console.warn(`(Sentry Error) => ${method_name} - ${errorMessage}`);
    this.setState({
      error: {
        message: errorMessage,
        code,
        status,
      },
    });
  },

  api: {
    hasError() {
      return !!this.getState("error");
    },

    getError() {
      return this.getState("error");
    },
  },
});
