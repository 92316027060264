import _ from "underscore";
import React from "react";
import queryString from "query-string";
import Api from "utils/api";
import AuthApi from "utils/auth-api";
import { Switch, Route, Redirect } from "react-router-dom";
import { Loading } from "@evertrue/et-components";
import OrgSource from "apps/org/org-source";
import LoginController from "apps/login/login-controller";
import ForgotPasswordController from "apps/password/forgot-password-controller";
import ResetPasswordController from "apps/password/reset-password-controller";
import ResetEmailPasswordController from "apps/password/reset-email-password-controller";
import LinkedinProcessLoginController from "apps/linkedin/linkedin-process-login-controller";
import LinkedinAutoLoginController from "apps/linkedin/linkedin-auto-login-controller";
import MagicLoginController from "apps/login/magic-login-controller";
import RegisterController from "apps/register/register-controller";
import RegisterEmailPasswordController from "apps/register/register-email-password-controller";
import RegisterConfirmation from "apps/register/register-confirmation";
import AppRedirectController from "apps/layout/app-redirect-controller";
import AppLayoutController from "apps/layout/app-layout-controller";
import AppWithAuthController from "apps/layout/app-with-auth-controller";
import AppErrorController from "apps/layout/app-error-controller";
import MFAController from "apps/mfa/mfa-controller";
import CreateMfaController from "apps/mfa/create-mfa-controller";
import SsoLoginController from "apps/sso/sso-login-controller";
import MobileAppsController from "apps/login/mobile-apps-controller";
import LinkedinAssociateController from "apps/linkedin/linkedin-associate-controller";
import EnterEmail from "apps/login/enter-email";
import AppPicker from "apps/layout/app-picker";
import UserPicker from "apps/session/user-picker";
import OrgPicker from "apps/org/org-picker";
import AppsConfig from "config/apps-config";
import cookies from "utils/cookies";
import SessionStore from "apps/session/session-store";
import MobileUtils from "utils/mobile-utils";
import VolunteerStart from "apps/volunteer/volunteer-start";

const setRedirect = (app, url) => {
  if (localStorage) {
    if (url) {
      localStorage.setItem(`et-${app}-referrer`, decodeURIComponent(url));
    } else {
      localStorage.removeItem(`et-${app}-referrer`);
    }
  }
};

const getRedirect = (app) => {
  if (localStorage) {
    return localStorage.getItem(`et-${app}-referrer`);
  }
};

const renderWithData = (childFunction) => {
  return ({ location, match }) => {
    const { app, slug } = match.params;

    if (app) {
      Api.setApp(AppsConfig.getAppKeyFor(app));
    }
    if (slug) {
      OrgSource.set({ slug });
    }

    const queryStringParams = queryString.parse(location.search);
    return childFunction({ ...match.params, params: queryStringParams });
  };
};

const renderLayout = (childFunction) => {
  return renderWithData((props) => {
    const { app, slug } = props;
    return (
      <AppLayoutController app={app} slug={slug}>
        {childFunction(props)}
      </AppLayoutController>
    );
  });
};

const renderWithAuth = (childFunction) => {
  return renderLayout((props) => {
    return <AppWithAuthController>{childFunction(props)}</AppWithAuthController>;
  });
};

const AppRouteController = () => {
  return (
    <div>
      <Switch>
        <Route path="/:app/login/linkedin/auto/:slug?" render={renderWithData(LinkedinAutoLoginController)} />

        <Route path="/:app/login/linkedin/process/:slug?" render={renderWithData(LinkedinProcessLoginController)} />

        <Route
          path="/:app/login/magic/:token/:slug?"
          render={({ match: { params }, location }) => {
            const queryStringParams = queryString.parse(location.search);
            const { device_id } = queryStringParams;
            const { app, slug, token } = params;
            return <MagicLoginController app={app} slug={slug} token={token} device_id={device_id} />;
          }}
        />

        <Route path="/:app/login/sso/:slug?" render={renderWithData(SsoLoginController)} />

        <Route
          path="/volunteers/login"
          render={({ match: { params }, location }) => {
            const queryStringParams = queryString.parse(location.search);
            return (
              <AppLayoutController app="volunteers">
                <AppWithAuthController>
                  <VolunteerStart params={queryStringParams} />
                </AppWithAuthController>
              </AppLayoutController>
            );
          }}
        />

        <Route path="/:app/login/:slug?" render={renderWithAuth(LoginController)} />

        <Route path="/:app/enter-email" render={renderLayout(EnterEmail)} />

        <Route path="/:app/mobileAppLink/:slug/token/:magicLinkToken" render={renderLayout(MobileAppsController)} />

        <Route path="/:app/mobile" render={renderLayout(MobileAppsController)} />

        <Route
          path="/:app/account/password/reset/:token/:slug?"
          render={({ match: { params }, location }) => {
            const queryStringParams = queryString.parse(location.search);
            const { device_id } = queryStringParams;
            const { app, token, slug } = params;
            return (
              <AppLayoutController app={app}>
                <ResetPasswordController
                  app={app}
                  token={token}
                  slug={slug}
                  type="forgot-password"
                  device_id={device_id}
                />
              </AppLayoutController>
            );
          }}
        />

        <Route
          path="/:app/account/password/change/:slug?"
          render={({ match: { params }, location }) => {
            const queryStringParams = queryString.parse(location.search);
            const { device_id } = queryStringParams;
            const { app, slug } = params;
            return (
              <AppLayoutController app={app}>
                <AppWithAuthController>
                  <ResetPasswordController
                    app={app}
                    token=""
                    slug={slug}
                    type="change-password"
                    device_id={device_id}
                  />
                </AppWithAuthController>
              </AppLayoutController>
            );
          }}
        />

        <Route
          path="/:app/account/password/:slug?"
          render={({ match: { params }, location }) => {
            const queryStringParams = queryString.parse(location.search);
            const { email } = queryStringParams;
            const { app, slug } = params;
            return (
              <AppLayoutController app={app} slug={slug}>
                <ForgotPasswordController app={app} slug={slug} emailAddress={email} />
              </AppLayoutController>
            );
          }}
        />

        {/*  TODO: Need more info on what is register confirm - think just used to confirm community emails? */}
        <Route path="/:app/account/register/confirm/:token/:slug?" render={renderLayout(RegisterConfirmation)} />

        {/* For setting an password and registering at the same time  */}
        <Route
          path="/:app/account/register_email_password"
          render={({ match: { params }, location }) => {
            const queryStringParams = queryString.parse(location.search);
            const { invite_token, email, device_id } = queryStringParams;
            const { app } = params;
            return (
              <AppLayoutController app={app}>
                <RegisterEmailPasswordController
                  app={app}
                  email={email}
                  invite_token={invite_token}
                  device_id={device_id}
                />
              </AppLayoutController>
            );
          }}
        />

        {/* For when you want to set a password but the user needs to be logged in first */}
        <Route
          path="/:app/account/reset_email_password"
          render={({ match: { params }, location }) => {
            const queryStringParams = queryString.parse(location.search);
            const { email, device_id, magic_token } = queryStringParams;
            const { app } = params;
            return (
              <AppLayoutController app={app}>
                <ResetEmailPasswordController app={app} email={email} magic_token={magic_token} device_id={device_id} />
              </AppLayoutController>
            );
          }}
        />
        <Route
          path="/:app/account/register/:invite_token/:slug?"
          render={({ match: { params }, location }) => {
            const queryStringParams = queryString.parse(location.search);
            const { device_id } = queryStringParams;
            const { app, slug, invite_token } = params;
            return (
              <AppLayoutController app={app}>
                <RegisterController app={app} slug={slug} invite_token={invite_token} device_id={device_id} />
              </AppLayoutController>
            );
          }}
        />

        <Route path="/:app/auth/select/user/:slug?" render={renderLayout(UserPicker)} />

        <Route
          path="/:app/auth/multifactor/create/:slug?"
          render={({ match: { params }, location }) => {
            const { app, slug } = params;
            const queryStringParams = queryString.parse(location.search);
            const { redirect_url } = queryStringParams;
            return (
              <AppWithAuthController>
                <AppLayoutController app={app}>
                  <CreateMfaController app={app} slug={slug} redirect_url={redirect_url} />
                </AppLayoutController>
              </AppWithAuthController>
            );
          }}
        />

        <Route path="/:app/auth/multifactor/:slug?" render={renderLayout(MFAController)} />

        <Route
          path="/:app/auth/select/org"
          render={({ match: { params }, location }) => {
            const { app, slug } = params;
            return (
              <div className="org-picker--wrapper">
                <AppLayoutController app={app}>
                  <OrgPicker app={app} slug={slug} />
                </AppLayoutController>
              </div>
            );
          }}
        />

        <Route
          path="/:app/auth/redirect/:oid_or_slug"
          render={(params) => {
            return renderWithData((data) => {
              const { app, oid_or_slug } = data;
              const _oid = parseInt(oid_or_slug, 10);
              const oid = _.isFinite(_oid) ? _oid : undefined;
              const use_scoped = AppsConfig.getUsesScopedSession(app);
              // if we were able to parse out an plausible OID, and the app supports scoped sessions,
              // then we'll use that OID,
              // if not, fall back to the old process.
              const path = getRedirect(app) || AppsConfig.getRoutePath(app);

              setRedirect(app);

              // If we have a device ID we need to create a scoped
              // session without using skiff so that the session passed to the
              // app has a prime token for re-upping the session in core android
              let headers = {};
              const device_id = MobileUtils.getDeviceIdFromStorage();
              const session = SessionStore.getSession() || {};

              if (device_id && MobileUtils.isMobile() && !!MobileUtils.getDeviceOS()) {
                headers = {
                  "Device-ID": device_id,
                  "Authorization-Provider": "EvertrueAuthToken",
                  Authorization: session.token,
                };
              }

              if (use_scoped && oid) {
                AuthApi.createScopedSession({ oid, headers }).then((scoped_session) => {
                  cookies.storeToken(scoped_session.token);
                  const url = path + `?oid=${oid}`;

                  if (MobileUtils.isMobile()) {
                    MobileUtils.postMessage({
                      session: scoped_session,
                      current_org: { id: oid },
                    }).then(() => {
                      console.log("redirecting to: ", url);
                      console.log("Scoped Session: ", scoped_session.type, scoped_session);
                      window.location = url;
                    });
                  } else {
                    console.log("redirecting to: ", url);
                    console.log("Scoped Session: ", scoped_session.type, scoped_session);
                    window.location = url;
                  }
                });
              } else {
                let url = `${path}?oid=${oid_or_slug}`;
                if (_.isNaN(parseInt(oid_or_slug, 10))) {
                  url = `${path}/${oid_or_slug}`;
                }
                if (MobileUtils.isMobile()) {
                  session.oid = oid;
                  MobileUtils.postMessage({ session: session }).then(() => {
                    window.location = url;
                  });
                } else {
                  window.location = url;
                }
              }
              return <Loading />;
            })(params);
          }}
        />

        <Route path="/:app/associate_linkedin/:slug?" render={renderWithAuth(LinkedinAssociateController)} />

        <Route path="/:app/error/:slug?" render={renderLayout(AppErrorController)} />

        {/* These redirect to evertrue because it needs to have an app key set */}
        <Route path="/forgot_password" render={() => <Redirect to="/evertrue/account/password" />} />

        <Route
          path="/reset_password/:token"
          render={({ match: { params } }) => <Redirect to={`/evertrue/account/password/reset/${params.token}`} />}
        />

        <Route
          path="/confirm/:token"
          render={({ match: { params } }) => <Redirect to={`/evertrue/account/register/confirm/${params.token}`} />}
        />

        <Route
          path="/:app"
          render={renderLayout(({ app }) => (
            <Redirect to={`/${app}/login`} />
          ))}
        />

        <Route
          path="/"
          render={renderLayout(() => (
            <AppPicker />
          ))}
        />
      </Switch>

      <Route
        path="/:app"
        render={({ match, location }) => {
          const { app } = match.params;
          // sets the app key for the app, needed to make requests
          Api.setApp(AppsConfig.getAppKeyFor(app));

          if (_.contains(["forgot_password", "reset_password", "confirm"], app)) {
            return null;
          }

          const { redirect } = queryString.parse(location.search);
          if (!AppsConfig.isValid(app)) {
            return <Redirect to="/" />;
          }
          if (redirect) {
            setRedirect(app, redirect);
          }

          return AppRedirectController({ app });
        }}
      />
    </div>
  );
};

export default AppRouteController;
