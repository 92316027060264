import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "utils/api";
import MfaSource from "apps/mfa/mfa-source";
import ErrorLogger from "utils/error-logger";
import Utils from "utils/methods";
import MobileUtils from "utils/mobile-utils";

export default createSource("SessionSource", {
  actions: {
    createdSessionWithEmail: true,
    createdSessionWithLidsToken: true,
    createdSessionWithCookie: true,
    createdSessionWithMagicToken: true,
    createdSessionWithHeaders: true,
    createdSessionWithSsoTokens: true,
    createdSession: true,
    setSession: true,
    renewAttempted: true,
    setGoToResetEmailPassword: true,
    sessionError(method_name, extra) {
      this.require(_.isString(method_name), "method_name must be a string");
    },
    ssoLoginError(method_name, extra) {
      this.require(_.isString(method_name), "method_name must be a string");
    },
    setIsPostingToMobile(posting_to_mobile) {
      this.require(_.isBoolean(posting_to_mobile), "posting_to_mobile should be a boolean");
    },
  },

  createSession(options) {
    let url = "skiff";
    let xhrFields = {
      withCredentials: true,
    };

    let headers = !window.location.pathname.match(/super/gi) ? options.headers : _.omit(options.headers, "Device-ID");

    // if there is a device id need to use auth/session to get a prime token
    // the prime token is needed for mobile to renew session because skiff wont
    // if its a mobile app
    if (headers && headers["Device-ID"] && MobileUtils.isMobile() && !!MobileUtils.getDeviceOS()) {
      url = "auth/session";
      xhrFields = {};
    } else {
      // do not pass device-id into skiff as a header
      headers = _.omit(headers, "Device-ID");
    }

    const opts = _.extend(
      {},
      {
        url,
        xhrFields,
        error(xhr) {
          return options.error(xhr, headers);
        },
      },
      _.omit(options, "error", "headers"),
      { headers: headers }
    );
    return Api.post(opts);
  },

  promise: {
    createSessionWithHeaders(headers) {
      return this.createSession({
        headers,
        success: resp => this.actions.createdSessionWithHeaders(resp),
        error: xhr =>
          this.actions.sessionError("createSessionWithHeaders", {
            xhr,
            headers,
          }),
      });
    },

    createSessionPromiseWithEmail(username, password, deviceId) {
      const encoded_creds = `Basic ${btoa(`${username}:${password}`)}`;
      const headers = {
        Authorization: encoded_creds,
        "Authorization-Provider": "EvertrueBasicAuth",
        "Authorization-Multifactor": 1,
        "Authorization-Auto-Send": 1,
      };

      if (deviceId) {
        headers["Device-ID"] = deviceId;
      }

      return this.createSession({
        headers: headers,
        success: resp => this.actions.createdSessionWithEmail(resp),
        error: (xhr, headers) => {
          if (xhr && xhr.status === 403) {
            const response = Utils.jsonParse(xhr.responseText, {});
            ErrorLogger.warn("SessionError 403", { response });
            switch (response.error_code) {
              case "otp_required":
                return MfaSource.setRequired(response, headers);
              case "invalid_otp":
                return MfaSource.setError(response.message);
              default:
                if (_.contains(["mfa_locked", "mfa_required"], response.error_code)) {
                  return this.setState({
                    error: { message: response.message, status: xhr.status },
                  });
                }
            }
          }
        },
      });
    },

    attemptSessionRenewal() {
      return this.createSession({
        disableAlerts: true,
        headers: {
          "Authorization-Multifactor": 1,
          "Authorization-Auto-Send": 1,
        },
        xhrFields: {
          withCredentials: true,
        },
        success: resp => this.actions.createdSessionWithCookie(resp),
        error: (xhr, headers) => {
          // set renew to attepted so if theres no session but we tried to get one
          // then proceed to login
          this.actions.renewAttempted();
        },
      });
    },
  },

  api: {
    createSessionWithEmail(username, password, device_id) {
      const encoded_creds = `Basic ${btoa(`${username}:${password}`)}`;
      const headers = {
        Authorization: encoded_creds,
        "Authorization-Provider": "EvertrueBasicAuth",
        "Authorization-Multifactor": 1,
        "Authorization-Auto-Send": 1,
      };

      if (device_id) {
        headers["Device-ID"] = device_id;
      }

      return this.createSession({
        headers: headers,
        success: resp => this.actions.createdSessionWithEmail(resp),
        error: (xhr, headers) => this.actions.sessionError("createSessionWithEmail", { xhr, headers }),
      });
    },

    createSessionWithLidsToken(user_id, lids_token, device_id) {
      const headers = {
        "Authorization-Provider": "linkedinaccesstoken",
        Authorization: lids_token,
        "Authorization-User": user_id,
        "Authorization-User-Picker": !user_id ? 1 : undefined,
        "Authorization-Multifactor": 1,
        "Authorization-Auto-Send": 1,
      };
      // Need the device id to create get a prime token
      // which will be used for re-upping in core android
      if (device_id) {
        headers["Device-ID"] = device_id;
      }
      if (lids_token) {
        return this.createSession({
          headers: headers,
          success: resp => this.actions.createdSessionWithLidsToken(resp, lids_token),
          error: (xhr, headers) =>
            this.actions.sessionError("createSessionWithLidsToken", {
              xhr,
              headers,
            }),
        });
      }
      return this.actions.sessionError("Missing LI Access Token", {});
    },

    createSessionWithCookie() {
      return this.createSession({
        disableAlerts: true,
        headers: {
          Authorization: null,
          "Authorization-Provider": null,
          "Authorization-Multifactor": 1,
          "Authorization-Auto-Send": 1,
        },
        xhrFields: {
          withCredentials: true,
        },
        success: resp => this.actions.createdSessionWithCookie(resp),
        error: (xhr, headers) =>
          this.actions.sessionError("createSessionWithCookie", {
            xhr,
            headers,
          }),
      });
    },

    setGoToResetEmailPassword(email) {
      this.actions.setGoToResetEmailPassword(email);
    },

    createSessionWithMagicToken(magic_token, device_id, email_for_setting_password) {
      // if we want to set the password, need to go through whole log in flow first
      // and then will need to check this before redirecting back into the app
      if (email_for_setting_password) {
        this.actions.setGoToResetEmailPassword(email_for_setting_password);
      }

      const headers = {
        "Authorization-Provider": "EvertrueLinkToken",
        "Authorization-Auto-Send": 1,
        "Authorization-Multifactor": 1,
        Authorization: magic_token,
      };
      if (device_id) {
        headers["Device-ID"] = device_id;
      }
      return this.createSession({
        headers,
        success: resp => this.actions.createdSessionWithMagicToken(resp, magic_token),
        error: xhr => {
          this.actions.sessionError("createSessionWithMagicToken", {
            xhr,
            headers,
          });
        },
      });
    },

    createSessionWithSsoTokens(tokens, device_id) {
      const headers = {
        Authorization: tokens.access_token,
        "Authorization-Provider": "SsoAccessTokenStrategy",
        "Id-Token": tokens.id_token,
        "Refresh-Token": tokens.refresh_token,
        "Authorization-Multifactor": 1,
        "Authorization-Auto-Send": 1,
      };
      if (device_id) {
        headers["Device-ID"] = device_id;
      }
      return this.createSession({
        headers,
        success: resp => this.actions.createdSessionWithSsoTokens(resp),
        error: (xhr, headers) =>
          this.actions.ssoLoginError("createSessionWithSsoTokens", {
            xhr,
            headers,
          }),
      });
    },

    createdSession(session) {
      this.actions.createdSession(session);
    },

    setSession(session) {
      this.actions.setSession(session);
    },

    setIsPostingToMobile(posting_to_mobile) {
      this.actions.setIsPostingToMobile(posting_to_mobile);
    },
  },
});
