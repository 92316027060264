/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { createSource } from "@evertrue/et-flux";

import Utils from "utils/methods";
import Api from "utils/api";
import ErrorLogger from "utils/error-logger";

export default createSource("MfaSource", {
  actions: {
    createdSessionViaMfaAttempt: true,
    setRequired: true,
    setTrustDevice: true,
    setError: true,
    setStatus: true,
    submit: true,
    resend: true,
    clear: true,
  },

  api: {
    setRequired(data, request_headers) {
      return this.actions.setRequired(data, request_headers);
    },

    setTrustDevice(bool) {
      return this.actions.setTrustDevice(bool);
    },

    setError(message) {
      return this.actions.setError(message);
    },

    setStatus(status_string) {
      return this.actions.setStatus(status_string);
    },

    submit(input_code) {
      return this.actions.submit(input_code);
    },

    resend() {
      return this.actions.resend();
    },

    clear() {
      return this.actions.clear();
    },

    attemptFetchingPrompt() {
      this.setStatus("loading");
      const headers = {
        "Authorization-Auto-Send": 0,
        "Authorization-Multifactor": 1,
      };

      return Api.post({
        url: "skiff",
        headers,
        xhrFields: {
          withCredentials: true,
        },
        success: resp => {
          this.actions.createdSessionViaMfaAttempt(resp);
          return this.clear();
        },
        error: xhr => {
          ErrorLogger.captureRequest("MFASource.attemptFetchingPrompt", xhr);

          const response = Utils.jsonParse(xhr.responseText, {});
          if (xhr.status === 403) {
            return this.setRequired(response, headers);
          }
          return this.clear();
        },
      });
    },
  },
});
