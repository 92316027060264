import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import OrgSource from "apps/org/org-source";

const ORG_KEY = "et-accounts--org";

function getOrgDataFromStorage() {
  let org_data = {};

  if (localStorage) {
    const data = localStorage.getItem(ORG_KEY);
    if (!_.isEmpty(data)) {
      org_data = JSON.parse(data);
    }
  }
  return org_data;
}

export default createStore("OrgStore", {
  getInitialState() {
    return {
      current_org: getOrgDataFromStorage(),
      organizations: [],
      loading: false,
      currentOrgLoading: false,
      error: undefined,
    };
  },

  registerActions() {
    this.on(OrgSource.actions.set, org => {
      let current_org = org;
      const stored_org = this.getState("current_org");
      // if incomplete org object set (eg from route)
      // and matches stored org
      if (org.slug && !org.id && org.slug === stored_org.slug) {
        current_org = stored_org;
      }
      return this.setState({ current_org });
    });

    this.on(OrgSource.actions.reset, () => {
      if (localStorage) {
        localStorage.removeItem(ORG_KEY);
      }
      this.setState({ current_org: {} });
    });

    this.on(OrgSource.actions.fetchedOrg, org => {
      const org_data = _.pick(org, "name", "slug", "id", "sso_method");
      if (localStorage) {
        localStorage.setItem(ORG_KEY, JSON.stringify(org_data));
      }
      return this.setState({ current_org: org });
    });

    this.on(OrgSource.actions.fetchedOrgs, orgs => {
      return this.setState({ organizations: orgs, error: undefined });
    });

    this.on(OrgSource.actions.loading, is_loading => {
      return this.setState({ loading: is_loading });
    });

    this.on(OrgSource.actions.currentOrgLoading, is_loading => {
      return this.setState({ currentOrgLoading: is_loading });
    });

    return this.on(OrgSource.actions.error, response => {
      const message =
        (response != null ? response.message : undefined) ||
        (response != null ? response.error : undefined) ||
        "We've encountered an error getting your organizations";
      return this.setState({ error: message });
    });
  },

  api: {
    getOrgs() {
      const clean_orgs = _.compact(
        _.map(this.getState("organizations"), org => {
          if (org.name && org.id) {
            return _.extend({}, org, { value: org.id, label: org.name });
          }
        })
      );
      return _.sortBy(clean_orgs, "label");
    },

    getCurrent() {
      return this.getState("current_org");
    },

    getLoading() {
      return this.getState("loading");
    },

    getCurrentOrgLoading() {
      return this.getState("currentOrgLoading");
    },

    getError() {
      return this.getState("error");
    },
  },
});
