import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "utils/api";
import ErrorLogger from "utils/error-logger";
import MobileUtils from "utils/mobile-utils";

export default createSource("RegisterSource", {
  actions: {
    registeredWithLinkedin: true,
    registeredWithEmail: true,
    registrationError(method_name, xhr) {
      this.require(_.isString(method_name), "method_name must be a string");
    },
    beginRegisterWithLinkedin(params) {
      this.require(_.isObject(params), "params must be an object");
    },
  },

  promise: {
    confirm(confirmation_token) {
      return new Promise((resolve, reject) =>
        Api.get({
          url: "auth/registrations/confirmation",
          params: {
            confirmation_token,
          },
          success(data) {
            return resolve({ status: "success", message: "You've been registered!" });
          },
          error(xhr) {
            let message;
            ErrorLogger.captureRequest("RegisterSource.promise.confirm", xhr);

            if (xhr.responseJSON && _.contains(xhr.responseJSON.confirmation_token, "is invalid")) {
              message = "There was a problem with your confirmation token. It may be expired.";
            } else {
              message = xhr.responseJSON || "Error registering your user.";
            }
            return reject({ status: "error", message });
          },
        })
      );
    },
  },

  api: {
    beginRegisterWithLinkedin(params, app) {
      // get linkedin access token by redirecting and authing with Linkedin
      // on successful LI auth, will redirect to app and get linkedin_access_token by calling
      // LinkedinLoginSource.authenticateWithLinkedin
      // if params includes invite_token, will call RegisterSource.registerWithLinkedin
      this.actions.beginRegisterWithLinkedin(params, app);
    },

    registerWithLinkedin(invite_token, li_token) {
      return Api.post({
        url: "lids/auth/registrations",
        params: {
          linkedin_access_token: li_token,
          invite_token,
        },
        headers: {
          "Authorization-Provider": null,
        },
        success: user => {
          this.actions.registeredWithLinkedin(user.id, li_token);
        },
        error: xhr => {
          // expect 422 if already registered
          if (xhr.status === 422) {
            this.actions.registeredWithLinkedin(null, li_token);
          } else {
            ErrorLogger.captureRequest("RegisterSource.promise.registerWithLinkedin", xhr);
            return this.actions.registrationError("registerWithLinkedin", { xhr });
          }
        },
      });
    },

    registerWithEmail(invite_token, email, device_id, password, password_confirmation) {
      let url = "skiff/register";
      const headers = {};
      let data = {
        user: {
          invite_token: invite_token,
          email: email,
        },
      };

      if (device_id) {
        headers["Device-ID"] = device_id;
      }

      if (password || password_confirmation) {
        data["user"]["password"] = password;
        data["user"]["password_confirmation"] = password_confirmation;
      }

      return Api.post({
        url: url,
        headers,
        xhrFields: { withCredentials: true },
        data: data,
        success: ({ session }) => this.actions.registeredWithEmail(session),
        error: xhr => {
          // send to mobile
          if (MobileUtils.isMobile()) {
            const error_object = { error: "Registration Failed", email, status: xhr.status };
            MobileUtils.postMessage(error_object);
          }

          this.actions.registrationError("registerWithEmail", { xhr });
        },
      });
    },
  },
});
