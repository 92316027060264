import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@evertrue/et-components";

const propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const defaultProps = {
  message: "Success!",
};

const SuccessMessage = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <div className="success-message">
      <Icon icon="check-circle" className="success-message--icon" />
      <div className="success-message--text"> {message} </div>
    </div>
  );
};

SuccessMessage.displayName = "SuccessMessage";
SuccessMessage.propTypes = propTypes;
SuccessMessage.defaultProps = defaultProps;

export default SuccessMessage;
