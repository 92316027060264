import _ from "underscore";
import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import SafeRedirect from "components/safe-redirect";
import SessionStore from "apps/session/session-store";
import OrgStore from "apps/org/org-store";
import AppErrorStore from "apps/layout/app-error-store";
import MfaStore from "apps/mfa/mfa-store";
import UserStore from "apps/user/user-store";
import AppsConfig from "config/apps-config";
import MobileUtils from "utils/mobile-utils";

export default createComponent("AppRedirectController", {
  propTypes: {
    app: PropTypes.string.isRequired,
  },

  registerStores() {
    this.on(SessionStore, () => ({
      users: SessionStore.getUserOptions(),
      session: SessionStore.getSession(),
      postingToMobile: SessionStore.getIsPostingToMobile(),
      reset_email_password: SessionStore.getResetEmailPassword(),
    }));

    this.on(OrgStore, () => ({
      orgs: OrgStore.getOrgs(),
      current_org: OrgStore.getCurrent(),
    }));

    this.on(AppErrorStore, () => ({
      has_error: AppErrorStore.hasError(),
    }));

    this.on(MfaStore, () => ({
      mfa_required: MfaStore.isRequired(),
      mfa_required_for_org: MfaStore.isRequiredForOrg(),
      mfa_state: MfaStore.getMfaState(),
    }));

    this.on(UserStore, () => ({
      user_has_li_association: UserStore.getUserHasLinkedinAssociation(),
    }));
  },

  render() {
    const { session = {}, current_org = {}, postingToMobile, reset_email_password } = this.state;

    const user = session.user || {};
    const slug = current_org.slug || "";
    const app = this.props.app;

    const has_token = !!session.token;
    const has_organization = !!current_org.id;

    if (!has_organization && this.state.orgs && this.state.orgs.length === 1){
      this.setState({current_org: this.state.orgs[0]});
    }

    const has_fetched_orgs = (this.state.orgs != null ? this.state.orgs.length : undefined) > 1;
    const has_user_options_but_no_session = !_.isEmpty(this.state.users) && !has_token;

    const needs_li_association = false;

    const isPasswordChangeRoute = !!window.location.pathname.match(/\/password\/change/gi);
    const isPasswordResetRoute = !!window.location.pathname.match(/\/password\/reset/gi);
    const isConfirmationRoute = !!window.location.pathname.match(/-confirmation/gi);
    const isCreateMfaRoute = !!window.location.pathname.match(/multifactor\/create/gi);
    const isMobileAppLinkRoute = !!window.location.pathname.match(/mobileAppLink/gi);
    const isAuthenticatedRouteWithoutRedirect =
      isPasswordChangeRoute || isPasswordResetRoute || isConfirmationRoute || isCreateMfaRoute || isMobileAppLinkRoute;

    const deviceId =
      !!window.location.search.match(/device_id=([^&\n]+)/) && window.location.search.match(/device_id=([^&\n]+)/)[1];
    const deviceIdSearchString = deviceId ? `?device_id=${deviceId}` : "";
    const deviceIdSearchMultiString = deviceId ? `&device_id=${deviceId}` : "";
    const isMobile = !!MobileUtils.getDeviceOS() && MobileUtils.isMobile();
    const shouldChooseMobileApp = !deviceId && isMobile;

    return (
      <div>
        {(() => {
          if (this.state.has_error) {
            return <SafeRedirect to={`/${app}/error/${slug}`} />;
          } else if (has_token && needs_li_association) {
            return <SafeRedirect to={`/${app}/associate_linkedin/${slug}${deviceIdSearchString}`} />;
          } else if (
            app !== "volunteers" &&
            window.location.pathname.match(/login\/magic/gi) &&
            shouldChooseMobileApp
          ) {
            const hasAppRoot =
              !!AppsConfig.getProp(app, "android_app_root") || !!AppsConfig.getProp(app, "ios_app_root");

            if (hasAppRoot) {
              const magicLinkToken =
                !!window.location.pathname.match(/login\/magic\/(.*)\//) &&
                window.location.pathname.match(/login\/magic\/(.*)\//)[1];

              return (
                <SafeRedirect to={`/${app}/mobileAppLink/${slug}/token/${magicLinkToken}${deviceIdSearchString}`} />
              );
            }
          }
          if (this.state.mfa_required_for_org && this.state.mfa_state !== "enabled" && !isMobileAppLinkRoute) {
            return <SafeRedirect to={`/${app}/auth/multifactor/create/${slug || ""}${deviceIdSearchString}`} />;
          } else if (!has_token && this.state.mfa_required && !isMobileAppLinkRoute) {
            return <SafeRedirect to={`/${app}/auth/multifactor/${slug}${deviceIdSearchString}`} />;
            // only allow mobile web logins to super, all other login types should go through their respective app
          } else if (has_token && (!postingToMobile || app === "super")) {
            // if you need to reset the password and you've already got your org
            if (reset_email_password && has_organization) {
              const encoded_email = encodeURIComponent(reset_email_password);
              return (
                <SafeRedirect
                  to={`/${app}/account/reset_email_password?email=${encoded_email}${deviceIdSearchMultiString}`}
                />
              );
            } else if (has_organization && !isAuthenticatedRouteWithoutRedirect) {
              // NOTE: this has a <SafeRedirect> here but for some reason when
              // going from the reset password page to the app it was not working and
              // I could not figure out why for the life of me
              return <Redirect to={`/${app}/auth/redirect/${current_org.id}${deviceIdSearchString}`} />;
            } else if ((has_fetched_orgs || user.super_user) && !isAuthenticatedRouteWithoutRedirect) {
              return <SafeRedirect to={`/${app}/auth/select/org${deviceIdSearchString}`} />;
            }
          } else if (has_user_options_but_no_session && !postingToMobile) {
            return <SafeRedirect to={`/${app}/auth/select/user/${slug}${deviceIdSearchString}`} />;
          }
          return null;
        })()}
      </div>
    );
  },
});
