import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import { Loading } from "@evertrue/et-components";
import { Link } from "react-router-dom";
import FormStatus from "components/form-status";
import AppsConfig from "config/apps-config";
import RegisterSource from "apps/register/register-source";

export default createComponent("RegisterConfirmController", {
  propTypes: {
    app: PropTypes.string,
    slug: PropTypes.string,
    token: PropTypes.string,
  },

  getInitialState() {
    return {
      status: undefined,
      message: undefined,
    };
  },

  componentDidMount() {
    RegisterSource.promise
      .confirm(this.props.token)
      .then(resp => this.setState(resp))
      .catch(resp => this.setState(resp));
  },

  renderLink() {
    const { app, slug } = this.props;

    switch (this.state.status) {
      case "success":
        if (AppsConfig.isEnabled(app)) {
          return (
            <Link to={`/${app}/login/${slug || ""}`}>
              <button> Login </button>
            </Link>
          );
        }
        break;
      case "error":
        if (slug) {
          return (
            <Link to={`/${app}/auth/redirect/${slug || ""}`}>
              <button> Return to App </button>
            </Link>
          );
        }
        break;
      default:
        return <Loading />;
    }
    return null;
  },

  render() {
    const { status } = this.state;
    let { message } = this.state;
    if (_.isObject(message)) {
      message = message.message;
    }

    return (
      <div>
        <FormStatus status={status} message={message} />
        {this.renderLink()}
      </div>
    );
  },
});
