import $ from "jquery";
import SessionSource from "../apps/session/session-source";

const MOBILE_WIDTH = 1428;
const DEVICE_ID_KEY = "et-accounts--device-id";

export default {
  postMessage(msg_obj) {
    SessionSource.setIsPostingToMobile(true);
    return new Promise((resolve) => {
      // add a delay before the post message is called so that the webview browser
      // has time to initialize
      window.setTimeout(() => {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify(msg_obj));
        } else {
          window.postMessage(JSON.stringify(msg_obj), "*");
        }
        SessionSource.setIsPostingToMobile(false);
        // add a slight delay before the promise is resolved, giving the app time to
        // recieve the postmessage. (If the actions in the resolve cause the page to change
        // this prevents the postmessage from getting lost)
        window.setTimeout(() => {
          resolve();
        }, 1000);
      }, 1000);
    });
  },

  isMobile() {
    const $win = $(window);
    const width = $win.outerWidth();
    return width <= MOBILE_WIDTH;
  },

  getDeviceOS() {
    if (navigator.userAgent.match(/Android/i)) {
      return "android";
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return "ios";
    } else if (navigator.userAgent.match(/Macintosh/i) && navigator.maxTouchPoints > 0) {
      // This check is for iPads using Safari: https://developer.apple.com/forums/thread/119186
      // By default, safari uses a user agent for desktop
      // this setting can be changed by the user and the iPad block above will be executed
      return "ios";
    }

    return null;
  },

  storeDeviceId(device_id) {
    if (localStorage) {
      localStorage.setItem(DEVICE_ID_KEY, device_id);
    }
  },

  getDeviceIdFromStorage() {
    if (localStorage) {
      return localStorage.getItem(DEVICE_ID_KEY);
    }
    return null;
  },

  getAppDeepLink(root, path = "", params) {
    const pathname = path.match(/^\//) ? path.slice(1) : path;
    let query_params = params || window.location.search || "";
    if (params && !params.match(/\?/)) {
      query_params = `?${params}`;
    }

    return `${root}://${pathname}${query_params}`;
  },
};
