import _ from "underscore";
import React from "react";
import { connect } from "@evertrue/et-flux";
import PropTypes from "prop-types";
import SessionStore from "apps/session/session-store";
import SessionSource from "apps/session/session-source";
import UserSource from "apps/user/user-source";
import { Loading } from "@evertrue/et-components";

const mapStateToProps = () => {
  return {
    session: SessionStore.getSession() || {},
    renewAttempted: SessionStore.getRenewAttempted(),
  };
};

const AppWithAuthController = props => {
  const [local_session, setLocalSession] = React.useState({});

  const hasSession = () => {
    return !_.isEmpty(local_session) || props.session.token;
  };

  // try renewing session if there isn't one already
  React.useEffect(() => {
    if (!hasSession()) {
      SessionSource.promise
        .attemptSessionRenewal()
        .then(session => {
          UserSource.setCurrentUser(session.user);
          setLocalSession(session);
        })
        .catch(() => setLocalSession({}));
    }
    // eslint-disable-next-line
  }, []);

  return <div className="app-with-auth">{hasSession() || props.renewAttempted ? props.children : <Loading />}</div>;
};

AppWithAuthController.propTypes = {
  children: PropTypes.any,
  // from stores
  renewAttempted: PropTypes.bool,
  session: PropTypes.object,
};

export default connect(AppWithAuthController, [SessionStore], mapStateToProps);
