import React from "react";
import Api from "utils/api";
import PropTypes from "prop-types";
import { Loading, Icon, Button } from "@evertrue/et-components";

const MagicLinkButton = props => {
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const sendMagicLink = () => {
    let url = "";
    let params = {};
    const isLocal = !!window.location.host.match(/local/gi);

    if (window.location.pathname.match(/volunteer/gi)) {
      url = "assignments-java/v2/auth";
    } else {
      url = "auth/link_tokens/create";

      if (isLocal && props.deviceId) {
        params = { local: true, device_id: props.deviceId, application: props.app, slug: props.slug };
      } else if (isLocal) {
        params = { local: true, application: props.app, slug: props.slug };
      } else if (props.deviceId) {
        params = { device_id: props.deviceId, application: props.app, slug: props.slug };
      } else {
        params = { application: props.app, slug: props.slug };
      }
    }

    Api.post({
      url: url,
      data: { email: props.email },
      params: params,
      headers: {
        "Authorization-Provider": null,
        Authorization: null,
      },
      statusCode: {
        202() {
          if (props.app !== "volunteers") {
            setLoading(false);
          }
          props.onSuccess();
        },
      },
      error: xhr => {
        if (xhr.status !== 202) {
          if (props.app !== "volunteers") {
            setLoading(false);
          }
          setError("There was a problem sending the link. Please try again.");
        }
      },
    });
  };

  const sendMagicLinkOrRegister = () => {
    if (props.app !== "volunteers") {
      setLoading(true);
    }

    // register the user before sending a magic link email if using the registration flow
    if (props.inviteToken) {
      let params = {};
      const isLocal = !!window.location.host.match(/local/gi);

      if (isLocal) {
        params = { local: true, application: props.app };
      } else {
        params = { application: props.app };
      }

      Api.post({
        url: "auth/registrations",
        data: { user: { invite_token: props.inviteToken, email: props.email } },
        params: params,
        headers: {
          "Authorization-Provider": null,
          Authorization: null,
        },
        statusCode: {
          202() {
            props.onSuccess();
          },
        },
        error: xhr => {
          if (xhr.status !== 202) {
            // registration failed, user might already exist. try sending a link token
            sendMagicLink();
          }
        },
      }).then(() => {
        // registration succeeded, user exists now. try sending a link token
        sendMagicLink();
      });
    } else {
      // non-registration flow, just send the link token
      sendMagicLink();
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Button type="secondary" onClick={sendMagicLinkOrRegister} disabled={!!props.disabled}>
        <Icon icon="magiclink" className="magic-link-btn--icon" />
        Email Me A Link
      </Button>
      {error ? <div className="magic-link--error">{error}</div> : null}
    </React.Fragment>
  );
};

MagicLinkButton.propTypes = {
  email: PropTypes.string,
  inviteToken: PropTypes.string,
  deviceId: PropTypes.string,
  app: PropTypes.string,
  slug: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default MagicLinkButton;
