import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import SessionSource from "apps/session/session-source";
import OrgSource from "apps/org/org-source";

const SSO_DATA_KEY = "et-accounts--token-data";

const getSsoDataFromStorage = () => {
  let sso_data = {};

  if (localStorage) {
    const data = localStorage.getItem(SSO_DATA_KEY);
    if (!_.isEmpty(data)) {
      sso_data = JSON.parse(data);
    }
  }
  return sso_data;
};

export default createStore("SsoStore", {
  getInitialState() {
    return {
      auth_url: "",
      sso_data: getSsoDataFromStorage(),
    };
  },

  registerActions() {
    this.on(OrgSource.actions.fetchedSsoData, this.respondToFetchedSsoData);

    this.on(SessionSource.actions.createdSessionWithSsoTokens, this.respondToCreatedSession);
  },

  respondToFetchedSsoData(auth_url, sso_data) {
    this.setState({ auth_url });

    if (localStorage) {
      const json_data = JSON.stringify(sso_data);
      localStorage.setItem(SSO_DATA_KEY, json_data);
    }
  },

  respondToCreatedSession() {
    if (localStorage) {
      localStorage.removeItem(SSO_DATA_KEY);
    }
  },

  api: {
    getAuthUrl() {
      return this.getState("auth_url");
    },
    getSsoData() {
      return this.getState("sso_data");
    },
  },
});
