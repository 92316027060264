/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Methods from "utils/methods";
import FormStatus from "components/form-status";
import Api from "utils/api";
import ErrorLogger from "utils/error-logger";
import EmailStore from "apps/login/email-store";
import { Button } from "@evertrue/et-components";
import SafeRedirect from "components/safe-redirect";
import Confirmation from "components/confirmation";

const ForgotPasswordController = props => {
  const { app, slug, emailAddress } = props;
  const [statusInfo, setStatusInfo] = React.useState({ message: undefined, status: undefined });
  const { status, message } = statusInfo;
  const [email, setEmail] = React.useState(EmailStore.getEmail() || emailAddress);
  const [disabled, setDisabled] = React.useState(!emailAddress);
  const [redirect, setRedirect] = React.useState(false);

  const handleEmailInputChange = e => {
    setEmail(e.target.value);
    setDisabled(!e.target.value.match(/.+@.+\..+/gi));
  };

  const handleResetEmail = () => {
    EmailStore.resetEmail();
    setEmail("");
    setDisabled(true);
  };

  const handleSubmit = () => {
    if (Methods.validateEmail(email)) {
      setStatusInfo({ status: undefined, message: undefined });

      if (app !== "volunteers") {
        EmailStore.setEmail(email);
      }

      const useLocalRedirect = window.location.hostname === "local-accounts.evertrue.com";
      const params = !!useLocalRedirect ? { local_redirect: true } : {};

      Api.post({
        url: "auth/registrations/password",
        params: params,
        data: { user: { email: email } },
      })
        .then(() => {
          setStatusInfo({
            status: "success",
            message: `A password reset was sent to ${email}.`,
          });
        })
        .catch(({ responseJSON = {}, responseText }) => {
          ErrorLogger.captureRequest("forgotPasswordReset", responseText);
          const message = responseJSON.message || "Error sending password.";
          setStatusInfo({ status: "error", message: message });
        });
    } else {
      setStatusInfo({
        status: "error",
        message: "Please enter a valid email",
      });
    }
  };

  if (redirect) {
    return <SafeRedirect to={`/${app}/login/${slug || ""}`} />;
  }

  if (status === "success" && app !== "volunteers") {
    return <Confirmation email={email} type="forgot-password-email" handleSubmit={() => setRedirect(true)} />;
  }

  return (
    <div className={`forgot-password is-${app}`}>
      <h2>Forgot Password</h2>

      <p className={`forgot-password--text is-${app}`}>
        Please enter the email associated with your account and we’ll send you instructions to reset your password.
      </p>

      <FormStatus status={status} message={message} />

      {EmailStore.isEmailSet() && app !== "volunteers" ? (
        <div className="fs-exclude-data login--current-email">
          <strong>{EmailStore.getEmail()}</strong>
          <a href="#" onClick={handleResetEmail}>
            Change
          </a>
        </div>
      ) : (
        <input
          type="text"
          className={`forgot-password--input is-${app}`}
          placeholder="Email Address"
          value={email}
          onChange={handleEmailInputChange}
        />
      )}

      <Button disabled={disabled} onClick={handleSubmit.bind(this)}>
        Send Instructions
      </Button>

      {/* "community" is currently the default app when there's a url to /forgot_password
        So the user clicked on a url with /forgot_password from volunteers and now it's community/account/password
        we don't want to redirect to community
        TODO - update all /forgot_password links to have the correct app in them */}
      {app !== "community" ? <Link to={`/${app}/login/`}>Return to Login</Link> : null}
    </div>
  );
};

ForgotPasswordController.propTypes = {
  app: PropTypes.string,
  slug: PropTypes.string,
  emailAddress: PropTypes.string,
};

export default ForgotPasswordController;
