import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import { Redirect } from "react-router-dom";
import AppErrorStore from "apps/layout/app-error-store";
import AppRedirectSource from "apps/layout/app-redirect-source";
import AppsConfig from "config/apps-config";
import SsoErrorConfig from "apps/sso/sso-error-config";
import Show from "components/show";
import ErrorMessage from "components/error-message";
import MobileUtils from "utils/mobile-utils";
import { Button } from "@evertrue/et-components";

export default createComponent("AppErrorController", {
  propTypes: {
    app: PropTypes.string,
    slug: PropTypes.string,
  },

  registerStores() {
    this.on(AppErrorStore, () => ({
      has_error: AppErrorStore.hasError(),
      error: AppErrorStore.getError(),
    }));
  },

  handleTryAgain() {
    const types = AppsConfig.getLoginTypes(this.props.app);
    if (types.length === 1 && _.contains(types, "Magic")) {
      AppRedirectSource.redirectToApp(this.props.app);
    } else {
      AppRedirectSource.redirectFromError();
    }
  },

  render() {
    const { app, slug } = this.props;
    const { message, code } = this.state.error || {};
    let error_message;
    let contact_link;
    let contact_label = "Contact Support";
    if (code && SsoErrorConfig[code]) {
      error_message = SsoErrorConfig[code].message;
      contact_link = SsoErrorConfig[code].contact_link;
      contact_label = SsoErrorConfig[code].contact_label;
    } else {
      error_message = message;
    }
    let redirect_url = `/${app}/login/${slug}`;
    if (app === "evertrue") {
      redirect_url = "/evertrue/enter-email";
    }
    const { appstore_url, playstore_url } = AppsConfig.getProps(app, ["appstore_url", "playstore_url"]);

    return (
      <div className={`app-error is-${app}`}>
        <div className={`app-error--body is-${app}`}>
          {!this.state.has_error ? <Redirect to={redirect_url} /> : undefined}

          <ErrorMessage message={error_message} />

          <Show when={!!contact_link}>
            <a className="app-error--contact-btn btn" href={`mailto:${contact_link}`}>
              {contact_label}
            </a>
          </Show>
          <Show when={!contact_link}>
            <Button className={`app-error-try-again--button is-${app}`} onClick={this.handleTryAgain}>
              Try Again
            </Button>
          </Show>
        </div>

        {/* We can hide this stuff if it's a mobile login, since it should only be applicable on desktop */}
        {!MobileUtils.isMobile() && !!MobileUtils.getDeviceOS() && app !== "volunteers" && (
          <div>
            <div className="enter-email--mobile">
              <div className="enter-email--mobile-text">Take EverTrue On The Road</div>
              <div className="enter-email--badges">
                {MobileUtils.getDeviceOS() === "ios" && (
                  <a href={appstore_url}>
                    <img className="enter-email--badge" src="/images/appstore-badge.png" alt="App Store" />
                  </a>
                )}
                {MobileUtils.getDeviceOS() === "android" && (
                  <a href={playstore_url}>
                    <img className="enter-email--badge" src="/images/google-play-badge.png" alt="Google Play Store" />
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
});
