import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import AppsConfig from "config/apps-config";
import Show from "components/show";
import MobileUtils from "utils/mobile-utils";

export default createComponent("MobileAppsController", {
  propTypes: {
    app: PropTypes.string.isRequired,
    slug: PropTypes.string,
    magicLinkToken: PropTypes.string,
    params: PropTypes.object,
  },

  handleContinueInApp(root, slug, magicLinkToken, deviceId) {
    if (root && !!magicLinkToken) {
      const deviceIdSearchMultiString = deviceId ? `&device_id=${deviceId}` : "";
      const mobileRedirectUrl = MobileUtils.getAppDeepLink(
        root,
        "",
        `?token=${magicLinkToken || magicLinkToken}${deviceIdSearchMultiString}`
      );

      window.location = mobileRedirectUrl;
    } else if (root) {
      window.location = MobileUtils.getAppDeepLink(root);
    }
  },

  render() {
    const { app, slug, magicLinkToken } = this.props;
    const deviceId = this.props.params && this.props.params.device_id;
    const device_os = MobileUtils.getDeviceOS() || "android";
    const app_name = AppsConfig.getNameFor(app);
    const { appstore_url, playstore_url, ios_app_root, android_app_root } = AppsConfig.getProps(app, [
      "appstore_url",
      "playstore_url",
      "ios_app_root",
      "android_app_root",
    ]);
    const root = device_os === "ios" ? ios_app_root : android_app_root;

    return (
      <div className="mobile-apps">
        <div className="mobile-apps--title">{`Welcome to ${app_name}!`}</div>

        <button
          className="mobile-apps--button"
          onClick={() => this.handleContinueInApp(root, slug, magicLinkToken, deviceId)}
        >
          Continue in App
        </button>

        <div className="mobile-apps--text">Don’t have our mobile app installed?</div>

        <a className="mobile-apps--link" href={device_os === "ios" ? appstore_url : playstore_url}>
          Download it now!
        </a>

        <Show when={device_os === "android"}>
          <a href={playstore_url} className="mobile-apps--badge-wrapper">
            <img className="mobile-apps--badge" src="/images/google-play-badge.png" alt="Google Play Store" />
          </a>
        </Show>

        <Show when={device_os === "ios"}>
          <a href={appstore_url} className="mobile-apps--badge-wrapper">
            <img className="mobile-apps--badge" src="/images/appstore-badge.png" alt="App Store" />
          </a>
        </Show>

        <div className="mobile-apps--image-wrapper">
          <img className="mobile-apps--image" src="/images/mobile-devices.png" alt="" />
        </div>
      </div>
    );
  },
});
