const GIVING_TREE_ROLES = ["GivingTree Owner", "GivingTree User"];
const VOLUNTEER_ROLES = ["Volunteer", "Volunteer Lead"];
const CONSOLE_ROLES = ["Console User"];
const DATA_ROLES = ["Admin"];

export const getAccessRoles = (url = "") => {
    if (url.includes("volunteers")) return VOLUNTEER_ROLES;
    if (url.includes("console")) return CONSOLE_ROLES;
    if (url.includes("data")) return DATA_ROLES;
    if (url.includes("super")) return [];

    return GIVING_TREE_ROLES;
}