import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Link, Button } from "@evertrue/et-components";
import MobileUtils from "utils/mobile-utils";
import EnterEmailStart from "components/enter-email-start";
import VolunteerLoginController from "apps/volunteer/volunteer-login-controller";

const VolunteerStart = (props) => {
  const { params = {} } = props;
  const [emailData, setEmailData] = React.useState({});
  const device_id = params.device_id;

  // if we later need to create a prime token then we need the device id,
  // this happens when trying to get a scoped session
  if (device_id) {
    MobileUtils.storeDeviceId(device_id);
  }

  // If the user's email domain is associated with an org with sso and is not a super user, go straight to sso login
  if (emailData.has_sso && !emailData.is_internal && emailData.url) {
    return <Redirect to={emailData.url} />;

    // if the user/org has sso but is an internal user (super user)
    // present all the login options including redirect to sso, for testing
  } else if (emailData.has_sso) {
    return (
      <React.Fragment>
        <VolunteerLoginController email={emailData.email} device_id={device_id} />
        <Button
          type="secondary"
          onClick={() => {
            window.location = emailData.url;
          }}
        >
          Log In with SSO
        </Button>
      </React.Fragment>
    );
  } else if (emailData.email) {
    return <VolunteerLoginController email={emailData.email} device_id={device_id} />;
  } else {
    return (
      <div className="volunteer-login">
        <div className="volunteer-login--header">Welcome!</div>
        <div>Volunteer by EverTrue is an invite only platform.</div>
        <div className="volunteer-login--text">If you’ve already been invited, please enter your email.</div>
        <EnterEmailStart
          app="volunteers"
          onSubmit={(resp) => {
            setEmailData(resp);
          }}
        />
        <div className="volunteer-login--help">
          Having trouble logging in?{" "}
          <Link
            href="https://help.evertrue.com/t/y4hnvj0/faq-volunteer-log-in-support"
            title="volunteer help knowledge base"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here for help
          </Link>
          .
        </div>
      </div>
    );
  }
};

VolunteerStart.propTypes = {
  params: PropTypes.object,
};

export default VolunteerStart;
