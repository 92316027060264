import React from "react";
import PropTypes from "prop-types";
import FormStatus from "components/form-status";
import { Button } from "@evertrue/et-components";

const SetPasswordForm = props => {
  const [statusInfo, setStatusInfo] = React.useState({
    status: undefined,
    message: undefined,
  });
  const { status, message } = statusInfo;

  const currentPasswordInput = React.useRef();
  const passwordInput = React.useRef();
  const confirmInput = React.useRef();
  const submitButtonText = props.submitButtonText || "Reset Password";
  const isVolunteers = props.app === "volunteers";
  const passwordPolicy = props.passwordPolicy || {};
  const passwordInputPlaceholderText = !isVolunteers ? "New Password" : "New Password (8 or more characters)";
  const requireCurrentPassword = !!props.requireCurrentPassword;
  const newPasswordLabelText = props.type === "create-account" ? "Enter Password" : "Enter New Password";
  const newPasswordConfirmationLabelText =
    props.type === "create-account" ? "Confirm Password" : "Confirm New Password";

  const handleSubmit = () => {
    const currentPassword =
      !!props.requireCurrentPassword &&
      currentPasswordInput &&
      currentPasswordInput.current &&
      currentPasswordInput.current.value;
    const password = passwordInput && passwordInput.current && passwordInput.current.value;
    const confirmation = confirmInput && confirmInput.current && confirmInput.current.value;

    if (password !== confirmation) {
      setStatusInfo({ status: "error", message: "New password does not match confirmation." });
    } else if (passwordPolicy.min_length && password.length < passwordPolicy.min_length) {
      setStatusInfo({
        status: "error",
        message: `New password must be at least ${passwordPolicy.min_length} characters.`,
      });
    } else if (passwordPolicy.max_length && password.length > passwordPolicy.max_length) {
      setStatusInfo({
        status: "error",
        message: `New password must be at most ${passwordPolicy.max_length} characters.`,
      });
    } else if (passwordPolicy.include_uppercase_letter && !password.match(/[A-Z]/)) {
      setStatusInfo({ status: "error", message: "New password must include an uppercase character." });
    } else if (passwordPolicy.include_lowercase_letter && !password.match(/[a-z]/)) {
      setStatusInfo({ status: "error", message: "New password must include a lowercase character." });
    } else if (passwordPolicy.include_number && !password.match(/[0-9]/)) {
      setStatusInfo({ status: "error", message: "New password must include a number." });
    } else if (passwordPolicy.include_special_character && !password.match(/[^A-Z0-9]/i)) {
      setStatusInfo({ status: "error", message: "New password must include a special character." });
    } else if (!!props.requireCurrentPassword && currentPassword && currentPassword.length < 8) {
      setStatusInfo({ status: "error", message: "Current password must be at least 8 characters." });
    } else if (password.length < 8) {
      setStatusInfo({ status: "error", message: "New password must be at least 8 characters." });
    } else {
      setStatusInfo({ status: undefined, message: undefined });
      props.onSubmit(currentPassword, password, confirmation);
    }
  };

  return (
    <React.Fragment>
      <FormStatus status={status} message={message} />

      {props.header()}
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {requireCurrentPassword && (
          <div>
            {!isVolunteers && <div class="forgot-password--title">Enter Current Password</div>}
            <input
              ref={currentPasswordInput}
              type="password"
              className="forgot-password--input fs-exclude-data"
              placeholder="Current Password"
            />
          </div>
        )}

        {!isVolunteers && <div class="forgot-password--title">{newPasswordLabelText}</div>}
        <input
          ref={passwordInput}
          type="password"
          className="forgot-password--input fs-exclude-data"
          placeholder={passwordInputPlaceholderText}
        />

        {!isVolunteers && <div class="forgot-password--title">{newPasswordConfirmationLabelText}</div>}
        <input
          ref={confirmInput}
          type="password"
          className="forgot-password--input fs-exclude-data"
          placeholder="Confirm New Password"
        />

        {!isVolunteers ? (
          <Button className="forgot-password--submit" onClick={handleSubmit.bind(this)}>
            {submitButtonText}
          </Button>
        ) : (
          <button type="submit">{submitButtonText}</button>
        )}
      </form>
    </React.Fragment>
  );
};

SetPasswordForm.propTypes = {
  loading: PropTypes.bool,
  submitButtonText: PropTypes.string,
  header: PropTypes.func,
  app: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default SetPasswordForm;
