/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import Api from "utils/api";
import AppsConfig from "config/apps-config";
import ErrorMessage from "components/error-message";
import SuccessMessage from "components/success-message";
import WarningMessage from "components/warning-message";
import MarketingBanner from "components/marketing-banner";
import EmailStore from "apps/login/email-store";
import { Button, Loading } from "@evertrue/et-components";
import MobileUtils from "utils/mobile-utils";
import SafeRedirect from "components/safe-redirect";

export default createComponent("EnterEmail", {
  propTypes: {
    app: PropTypes.string.isRequired,
    params: PropTypes.object,
  },

  getDefaultProps() {
    return {
      params: {},
    };
  },

  getInitialState() {
    return {
      email: EmailStore.getEmail() || (this.props.params && this.props.params.email),
      org_slug: "",
      error: "",
      displayOrgSlugInput: false,
      isInternal: false,
      disabled:
        !(EmailStore.getEmail() && EmailStore.getEmail().match(/.+@.+\..+/gi)) &&
        !(this.props.params && this.props.params.email && this.props.params.email.match(/.+@.+\..+/gi)),
      successMessage: "",
      warningMessage: "",
      loading: false,
    };
  },

  componentWillMount() {
    const { params } = this.props;
    if (params.error) {
      this.setState({ error: params.error });
    }
  },

  redirectUser(redirectUrl) {
    const { params } = this.props;
    const email = encodeURIComponent(this.state.email);
    const device_id = params.device_id;
    let url_params = "";

    if (email && device_id) {
      url_params = `?email=${email}&device_id=${device_id}`;
    } else if (email) {
      url_params = `?email=${email}`;
    } else if (device_id) {
      url_params = `?device_id=${device_id}`;
    }

    const url = `${redirectUrl}${url_params}`;
    window.location.replace(url);
  },

  handleSubmit(e) {
    e.preventDefault();
    const { email, org_slug } = this.state;

    if (email && email.trim()) {
      const encoded_email = encodeURIComponent(email.trim());
      const encodedOrgSlug = encodeURIComponent(org_slug.trim());
      const useLocalRedirect = window.location.hostname === "local-accounts.evertrue.com";
      let params = `email=${encoded_email}&org_slug=${encodedOrgSlug}&type=web`;

      if (useLocalRedirect) {
        params += "&local_redirect=true";
      }

      if (!EmailStore.isEmailSet()) {
        EmailStore.setEmail(this.state.email);
      }

      this.setState({ loading: true });

      Api.get({
        url: `auth/identity_providers/search?${params}`,
        success: (resp) => {
          if (!resp.redirect_url) {
            this.setState({
              displayOrgSlugInput: true,
              successMessage:
                "Your account is affiliated with multiple organizations.  Please enter the ID below for the organization you are trying to log into.",
              disabled: true,
            });
          } else {
            this.redirectUser(resp.redirect_url);
          }
          this.setState({ loading: false });
        },
        error: (xhr) => {
          const error = xhr.responseJSON.message || "Unknown Error";
          this.setState({ error, loading: false });
        },
      });
    }
  },

  renderOrgSlugInput() {
    return (
      <div>
        <div className="enter-email--org-slug-label">Organization ID</div>
        <input
          className="enter-email--org-slug-input"
          type="text"
          name="org_slug"
          placeholder="Organization ID"
          onChange={this.handleOrgSlugInputChange}
          onKeyDown={this.handleSubmitOnEnter}
        />
      </div>
    );
  },

  renderOrgSlugHelpText() {
    return (
      <div className="enter-email--org-slug-help-text">
        Not sure of your Organization ID?
        <br />
        Contact <a href="mailto:genius@evertrue.com">genius@evertrue.com</a> for assistance.
      </div>
    );
  },

  renderEverTrueHelpText() {
    return (
      <div className="enter-email--evertrue-help-text">
        <div>
          <div>Are you a volunteer looking to log-in to your organization’s platform?</div>
          <div>Click &nbsp;<a href="https://accounts.evertrue.com/volunteers/login">here </a> to log-in.</div>
        </div>
        <div style={{marginTop: "10px"}}>
          Don't have an EverTrue account? &nbsp;
          <a href="https://www.evertrue.com/demo/">Learn more.</a>
        </div>
      </div>
    );
  },

  renderMessage() {
    if (this.state.error && this.props.params.error) {
      return (
        <ErrorMessage
          message={
            <div>
              <div>{this.state.error}</div>
              <div>
                <span>If this problem persists, please contact </span>
                <a href={`mailto:genius@evertrue.com?subject=${encodeURIComponent(this.state.error)}`}>
                  genius@evertrue.com
                </a>
              </div>
            </div>
          }
        />
      );
    } else if (this.state.error) {
      return <ErrorMessage message={this.state.error} />;
    } else if (this.state.successMessage) {
      return <SuccessMessage message={this.state.successMessage} />;
    } else if (this.state.warningMessage) {
      return <WarningMessage message={this.state.warningMessage} />;
    }

    return false;
  },

  handleOrgSlugInputChange(e) {
    this.setState({
      org_slug: e.target.value,
      disabled: !e.target.value || !this.state.email.match(/.+@.+\..+/gi),
    });
  },

  handleEmailInputChange(e) {
    this.setState({
      email: e.target.value,
      disabled: !e.target.value.match(/.+@.+\..+/gi),
    });
  },

  handleResetEmail() {
    EmailStore.resetEmail();
    this.setState({
      email: "",
      disabled: true,
    });
  },

  handleSubmitOnEnter(e) {
    if (e.keyCode === 13) {
      this.handleSubmit(e);
    }
  },

  render() {
    const { params } = this.props;
    const { appstore_url, playstore_url, marketing_banner } = AppsConfig.getProps(this.props.app, [
      "appstore_url",
      "playstore_url",
      "marketing_banner",
    ]);

    const is_mobile = !!MobileUtils.getDeviceOS() && MobileUtils.isMobile();

    const should_choose_mobile_app =
      (!this.props.params || !this.props.params.device_id) && is_mobile && AppsConfig.getHasMobileLinks(this.props.app);

    if (should_choose_mobile_app) {
      return <SafeRedirect to={`/${this.props.app}/mobile`} />;
    }

    return (
      <div className="enter-email">
        {marketing_banner && <MarketingBanner message={marketing_banner} />}

        <div className={`enter-email--body is-${this.props.app}`}>
          {this.renderMessage()}
          {this.state.loading && <Loading />}

          <div className={`enter-email--label is-${this.props.app}`}>Please enter your email address</div>

          {EmailStore.isEmailSet() ? (
            <div className="enter-email--current-email">
              <strong>{EmailStore.getEmail()}</strong>
              <a href="#" onClick={this.handleResetEmail}>
                {" "}
                Change
              </a>
            </div>
          ) : (
            <input
              className={`enter-email--input is-${this.props.app}`}
              type="text"
              name="email"
              placeholder="Email Address"
              defaultValue={this.state.email}
              onChange={this.handleEmailInputChange}
              onKeyDown={this.handleSubmitOnEnter}
            />
          )}

          {this.state.displayOrgSlugInput && this.renderOrgSlugInput()}

          <Button
            className={`enter-email--button is-${this.props.app}`}
            disabled={this.state.disabled}
            onClick={this.handleSubmit}
          >
            {this.props.app === "evertrue" ? "Sign In" : "Submit"}
          </Button>

          {this.props.app === "evertrue" && this.renderEverTrueHelpText()}

          {this.state.displayOrgSlugInput && this.renderOrgSlugHelpText()}
        </div>

        {/* If there's a device id we know it's a mobile login and can hide this stuff which
         if only applicable when on desktop  */}
        {!params.device_id && !!MobileUtils.getDeviceOS() && (
          <div>
            <div className="enter-email--mobile">
              <div className="enter-email--mobile-text">Take EverTrue On The Road</div>
              <div className="enter-email--badges">
                {MobileUtils.getDeviceOS() === "ios" && (
                  <a href={appstore_url}>
                    <img className="enter-email--badge" src="/images/appstore-badge.png" alt="App Store" />
                  </a>
                )}
                {MobileUtils.getDeviceOS() === "android" && (
                  <a href={playstore_url}>
                    <img className="enter-email--badge" src="/images/google-play-badge.png" alt="Google Play Store" />
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
});
