/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";

import { createStore } from "@evertrue/et-flux";
import MfaSource from "apps/mfa/mfa-source";
import SessionSource from "apps/session/session-source";
import MobileUtils from "utils/mobile-utils";

const _getDefaults = () => ({
  prompt_data: {},
  request_headers: {},
  has_code_sent: false,
  mfa_required: false,
  token: "",
  error_message: undefined,
  status: undefined,
});

export default createStore("MfaStore", {
  getInitialState() {
    return _getDefaults();
  },

  registerActions() {
    this.on(MfaSource.actions.setRequired, (data, headers) =>
      this.setState({
        prompt_data: data.mfa,
        request_headers: headers,
        has_code_sent: data.otp_sent,
        mfa_required: data.mfa_required,
        token: data.token,
        status: undefined,
      })
    );

    this.on(MfaSource.actions.setTrustDevice, bool => {
      const data = _.clone(this.getState("prompt_data")) || {};
      data.device_trusted = bool;
      return this.setState({ prompt_data: data });
    });

    this.on(MfaSource.actions.setError, message => this.setState({ error_message: message }));

    this.on(MfaSource.actions.setStatus, status => this.setState({ status }));

    this.on(MfaSource.actions.clear, () => this.setState(_getDefaults()));

    this.on(MfaSource.actions.resend, () => {
      MfaSource.setStatus("resending");
      const headers = _.extend({}, this.getState("request_headers"), {
        "Authorization-Auto-Send": 1,
      });

      // if we are on mobile, add the device id to header
      const device_id =
        MobileUtils.isMobile() && !!MobileUtils.getDeviceOS() ? MobileUtils.getDeviceIdFromStorage() : undefined;
      if (device_id) {
        headers["Device-ID"] = device_id;
      }
      return SessionSource.promise.createSessionWithHeaders(headers);
    });

    return this.on(MfaSource.actions.submit, input_code => {
      MfaSource.setStatus("verifying");
      const headers = _.extend({}, this.getState("request_headers"), {
        "Authorization-OTP": input_code,
        "Authorization-Trust-Device": this.getDeviceTrusted() ? 1 : 0,
      });

      // if we are on mobile, add the device id to header
      const device_id =
        MobileUtils.isMobile() && !!MobileUtils.getDeviceOS() ? MobileUtils.getDeviceIdFromStorage() : undefined;
      if (device_id) {
        headers["Device-ID"] = device_id;
      }
      return SessionSource.promise.createSessionWithHeaders(headers).then(() => MfaSource.clear());
    });
  },

  api: {
    isRequired() {
      return !_.isEmpty(this.getState("prompt_data"));
    },

    isRequiredForOrg() {
      return !!this.getState("mfa_required");
    },

    hasCodeSent() {
      return this.getState("has_code_sent");
    },

    getStatus() {
      return this.getState("status");
    },

    getMfaToken() {
      return this.getState("token");
    },

    getPhoneNumber() {
      return __guard__(this.getState("prompt_data"), x => x.otp_mobile);
    },

    getDeviceTrusted() {
      return __guard__(this.getState("prompt_data"), x => x.device_trusted);
    },

    getPromptTrust() {
      return __guard__(this.getState("prompt_data"), x => x.prompt_trust);
    },

    getMfaState() {
      return __guard__(this.getState("prompt_data"), x => x.mfa_state);
    },

    getError() {
      return this.getState("error_message");
    },
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
