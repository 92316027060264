import $ from "jquery";
import _ from "underscore";
import Config from "config/env";

if (Config.isDevelopment()) {
  window.EverTrue = window.EverTrue || {};
  window.EverTrue.config = Config;
}

let _app_key;
let _oid;
let _session_token;

export default {
  setApp(app_key) {
    _app_key = app_key;
  },

  getAppKey() {
    return _app_key;
  },

  setSession(token) {
    _session_token = token;
  },

  getSessionToken() {
    return _session_token;
  },

  setOid(oid) {
    _oid = oid;
  },

  getOid() {
    return _oid;
  },

  post(options) {
    return this.request(_.extend({ type: "POST" }, options));
  },

  put(options) {
    return this.request(_.extend({ type: "PUT" }, options));
  },

  get(options) {
    return this.request(_.extend({ type: "GET" }, options));
  },

  delete(options) {
    return this.request(_.extend({ type: "DELETE" }, options));
  },

  requestWithSession(options) {
    return this.request(
      _.extend(
        {
          headers: {
            Authorization: _session_token,
            "Authorization-Provider": "evertrueauthtoken",
          },
        },
        options
      )
    );
  },

  request(options) {
    const opts = $.extend(true, {}, options);
    opts.url = Config.apiBase + options.url;
    opts.headers = _.extend(
      {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Application-Key": _app_key,
        "Cache-Control": "no-cache",
      },
      options.headers
    );

    if (_.isObject(options.data) && _.contains(["PUT", "POST"], opts.type)) {
      opts.data = JSON.stringify(options.data);
    }

    opts.params = _oid ? { oid: _oid } : {};
    _.each(options.params, (param, key) => {
      if (param) {
        opts.params[key] = param;
      }
    });

    if (!_.isEmpty(opts.params)) {
      opts.url += `?${$.param(opts.params)}`;
    }

    return $.ajax(opts);
  },
};
