import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@evertrue/et-components";

const propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const defaultProps = {
  message: "Warning.",
};

const WarningMessage = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <div className="warning-message">
      <Icon icon="alert-circle" className="warning-message--icon" />
      <div className="warning-message--text"> {message} </div>
    </div>
  );
};

WarningMessage.displayName = "WarningMessage";
WarningMessage.propTypes = propTypes;
WarningMessage.defaultProps = defaultProps;

export default WarningMessage;
