import _ from "underscore";

// Enabled requires application support
const config = {
  community: {
    name: "Your Alunmi Network",
    enabled: false,
    login_types: ["LI", "ET"],
    domain: "community",
    app_key: "590b6855ec417400015682e454c6acec8f371adf861b830b031fa9ad3ac84c90",
    login_header: "Log into your network",
    image: "/images/icon-community.png",
    logo: "/images/logo-evertrue-dark.png", // TODO: Replace with white labeling support
    is_white_labeled: true,
    color: "#FFFFFF",
    port: 3036,
  },
  volunteers: {
    name: "EverTrue for Volunteers",
    enabled: false,
    login_types: ["Magic", "ET", "SSO"],
    domain: "volunteer",
    app_key: "7cb9b8d4451567b725e81b998cdf46cfc58426f9936916c714acddbc9f082546",
    login_header: "Log into your network",
    image: "/images/icon-volunteers-v2.png",
    logo: "/images/volunteer-logo-dark-2x.png",
    color: "#fff",
    port: 3010,
  },
  evertrue: {
    name: "EverTrue Advancement",
    enabled: false,
    login_types: ["Magic", "LI", "ET", "SSO"],
    domain: "app",
    app_key: "815e8d01be8f78a41d1c71eb652b8be124b89058b74d284c6bb752a034dbb301",
    login_header: "Sign Into EverTrue",
    image: "/images/icon-evertrue.png",
    logo: "/images/logo-evertrue-dark.png",
    color: null,
    port: 3000,
    playstore_url: "http://play.google.com/store/apps/details?id=com.evertrue.mobile",
    appstore_url: "http://www.evertrue.com/getapp",
    android_app_root: "etapp",
    ios_app_root: "givingtree",
    // marketing_banner: (
    //   <div>
    //     RAISE 2019: Join us in Boston on September 19 - 20 for two days of advancement content that inspires
    //     <a
    //       href="https://raisebyevertrue.com/?utm_source=product&utm_medium=ad&utm_campaign=raise2019"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       {"REGISTER NOW >"}
    //     </a>
    //   </div>
    // ), // after raise, comment this out but don't delete it, as we'll just want to add it back next year
  },
  console: {
    name: "Admin Console",
    enabled: false,
    login_types: ["ET"],
    domain: "console",
    app_key: "89567e74bec3afafc5f46db72b1a9323f41858256e3bec5dc9e522a7b3f3518f",
    port: 3008,
  },
  super: {
    name: "EverTrue Super",
    login_types: ["Magic", "LI", "ET", "SSO"],
    domain: "super",
    app_key: "dc3c8823169fcba088c9c3134e992d69d80ef09ce2382e4daa5cc8a6c1b5a6d4",
    port: 3040,
    login_header: "Sign Into EverTrue Super",
    image: "/images/icon-evertrue.png",
    logo: "/images/logo-evertrue-dark.png",
    enabled: false,
  },
  data: {
    name: "EverTrue Data",
    login_types: ["Magic", "LI", "ET", "SSO"],
    domain: "data",
    app_key: "89567e74bec3afafc5f46db72b1a9323f41858256e3bec5dc9e522a7b3f3518f",
    port: 3456,
    login_header: "Sign Into EverTrue Data",
    image: "/images/icon-evertrue.png",
    logo: "/images/logo-evertrue-dark.png",
    color: null,
    enable: false,
    scoped_session: true,
  },
};

export default {
  getAll() {
    return _.compact(
      _.map(config, (app, key) => {
        if (app.enabled) {
          return _.extend({ key }, app);
        }
      })
    );
  },

  isValid(app, warn) {
    if (!config[app] && warn) {
      console.warn(`${app} has not been defined`);
    }
    return !!config[app];
  },

  isEnabled(app) {
    return this.getProp(app, "enabled");
  },

  getProp(app, prop) {
    if (this.isValid(app, true)) {
      return config[app] != null ? config[app][prop] : undefined;
    }
  },

  getProps(app, props = []) {
    const results = {};
    _.each(props, prop => {
      const value = this.getProp(app, prop);
      if (value) {
        results[prop] = value;
      }
    });
    return results;
  },

  getLogo(app) {
    if (app == null) {
      app = "evertrue";
    }
    return this.getProp(app, "logo");
  },

  getLogoColor(app) {
    if (app == null) {
      app = "evertrue";
    }
    return this.getProp(app, "color");
  },

  getAppKeyFor(app) {
    return this.getProp(app, "app_key");
  },

  getNameFor(app) {
    return this.getProp(app, "name");
  },

  getLoginTypes(app) {
    return this.getProp(app, "login_types");
  },

  getUsesScopedSession(app) {
    return !!this.getProp(app, "scoped_session");
  },

  getRoutePath(app) {
    if (this.isValid(app, true)) {
      const domain = config[app] != null ? config[app].domain : undefined;
      if (!domain) {
        throw new Error("Your config needs a domain, you may have mispelled the key");
      }

      let new_path = __guard__(
        __guard__(window.location.origin.replace("accounts", domain), x1 => x1.split(/:\d{4}$/)),
        x => x[0]
      );
      if (window.location.origin != null ? window.location.origin.match("local") : undefined) {
        const port = config[app] != null ? config[app].port : undefined;
        new_path += `:${port}`;
      }
      return new_path;
    }
  },

  getHasMobileLinks(app) {
    return this.getProp(app, "playstore_url") || this.getProp(app, "appstore_url");
  },
};

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
