import React from "react";
import PropTypes from "prop-types";
import AppsConfig from "config/apps-config";
import AppFooter from "apps/layout/app-footer";
import Config from "config/env";
import AppLeftBanner from "./app-left-banner";

const AppLayoutController = (props) => {
  return (
    <div className={`app-layout is-${props.app}`}>
      {props.app === "evertrue" && <AppLeftBanner />}
      <div className={`app-layout--flex is-${props.app}`}>
        <div className={`app-layout--banner is-${props.app}`} />

        <div className={`app-layout--wrapper is-${props.app}`}>
          <div
            className={`app-layout--header is-${props.app}`}
            style={{
              backgroundColor: AppsConfig.getLogoColor(props.app),
              backgroundImage: `url(${AppsConfig.getLogo(props.app)})`,
            }}
          />

          <div className={`app-layout--module is-${props.app}`}>
            <div className={`app-layout--body is-${props.app}`}>{props.children}</div>
          </div>
        </div>

        {Config.isDevelopment() && (
          <div className="app-layout--env" onClick={Config.switchEnvironment}>
            {Config.isStageData() ? "Stage" : "Prod"}
          </div>
        )}

        <div className={`app-layout--footer is-${props.app}`}>
          {props.app === "volunteers" && (
            <img alt="Volunteers" src="/images/volunteer-email-img_lg@2x.png" className="app-layout--vol-img" />
          )}
          <AppFooter app={props.app} />
        </div>
      </div>
    </div>
  );
};

AppLayoutController.propTypes = {
  app: PropTypes.string,
  children: PropTypes.any,
};

export default AppLayoutController;
