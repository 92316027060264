import _ from "underscore";
import Api from "utils/api";
import { createSource } from "@evertrue/et-flux";

export default createSource("LinkedinAssociateSource", {
  actions: {
    associatedUserWithLinkedinAccount: true,
    linkedinError(method_name, extra) {
      return this.require(_.isString(method_name), "method_name must be a string");
    },
  },

  promise: {
    fetchLinkedinAccessToken(params) {
      return Api.get({
        url: "lids/callback",
        params,
        error: xhr => {
          this.actions.linkedinError("fetchLinkedinAccessToken", { xhr });
        },
      });
    },
  },

  api: {
    redirectToLinkedinAuth(app, slug) {
      return Api.get({
        url: "lids/auth",
        params: {
          callback_url: `${window.location.origin}/${app}/associate_linkedin/${slug}`,
          verify: false, // TODO whitelist
        },
        success: resp => {
          if (resp.authorize_url) {
            _.defer(() => {
              window.location = resp.authorize_url;
            });
          }
        },
        error: xhr => {
          this.actions.linkedinError("redirectToLinkedinAuth", { xhr });
        },
      });
    },

    associateUserWithLinkedinAccount(linkedin_access_token) {
      return Api.requestWithSession({
        type: "PUT",
        url: "lids/auth/association",
        params: { linkedin_access_token },
        success: () => {
          this.actions.associatedUserWithLinkedinAccount();
        },
        error: xhr => {
          this.actions.linkedinError("assiociateUserWithLinkedinAccount", { xhr });
        },
      });
    },
  },
});
