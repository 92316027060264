import React from "react";

const error_message = "Oops! There was a problem processing your request. ";
const et_support = "genius@evertrue.com";

const contact_et = {
  contact_link: et_support,
  contact_label: "Contact EverTrue Support",
  message: (
    <div>
      {error_message}
      Please contact
      <a className="error-message--link" href={`mailto:${et_support}`}>
        {et_support}
      </a>
    </div>
  ),
};

// key corresponds to responseText.error_code
// message (required) should be user-friendly error message
// contact_link should be email or link
export default {
  113: { message: "This user isn't a registered user in EverTrue" },
  114: { message: "This user isn't associated with the current organization" },
  115: contact_et,
  116: contact_et,
  117: contact_et,
  118: contact_et,
  119: contact_et,
  120: contact_et,
};
