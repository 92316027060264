import _ from "underscore";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import { Loading, AdvancedComboboxContent } from "@evertrue/et-components";
import { Link } from "react-router-dom";
import { searchCompare } from "utils/utils";
import OrgStore from "apps/org/org-store";
import OrgSource from "apps/org/org-source";
import SessionStore from "apps/session/session-store";
import MobileUtils from "utils/mobile-utils";

const mapStateToProps = () => {
  return {
    orgs: OrgStore.getOrgs(),
    loading: OrgStore.getLoading(),
    error: OrgStore.getError(),
    user: SessionStore.getUser(),
  };
};

const OrgPicker = props => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrgs = async () => {
      await OrgSource.fetchForUser(props.user);
    }
    fetchOrgs();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = item => {
    const org = _.findWhere(props.orgs, { id: item.value }) || {};
    setIsLoading(true);
    if (MobileUtils.isMobile()) {
      // send to mobile
      // set org after message has been posted, because if the window changes due to org
      // set then post message wont get sent
      MobileUtils.postMessage({ current_org: org }).then(() => {
        OrgSource.set(org);
      });
    } else {
      OrgSource.set(org);
    }
    setIsLoading(false);
  };

  const handleTryOrgFetch = async () => {
    setIsLoading(true);
    await OrgSource.fetchForUser(props.user);
    setIsLoading(false);
  };

  return (
    <div className="org-picker">
      {isLoading || props.loading ? <Loading /> : null}
      {props.error ? (
        <div className="org-picker--empty">
          {props.error}
          <a href="#retry" onClick={handleTryOrgFetch}>
            Try Again
          </a>
        </div>
      ) : null}
      {_.isEmpty(props.orgs) ? (
        <div className="org-picker--empty">
          You do not have access to any EverTrue organizations.
          <div>
            <Link to={`/${props.app}/login/${props.slug || ""}`}>Return to Login</Link>
          </div>
        </div>
      ) : (
        <div className="org-picker--full-height">
          <div className="org-picker--header">Select an organization</div>

          <div className="org-picker--body">
            <div className="org-picker--selector">
              <AdvancedComboboxContent
                searchable
                onSearch={searchText => {
                  setSearchText(searchText);
                }}
                options={_.filter(props.orgs, org => {
                  return searchCompare(org.label, searchText) || searchCompare(org.value.toString(), searchText);
                })}
                focusOnMount
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

OrgPicker.propTypes = {
  app: PropTypes.string,
  slug: PropTypes.string,
  // from the store
  user: PropTypes.object,
  orgs: PropTypes.array,
  loading: PropTypes.bool,
};

export default connect(OrgPicker, [OrgStore, SessionStore], mapStateToProps);
