import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const MarketingBanner = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <div className="marketing-banner">
      <div> {message} </div>
    </div>
  );
};

MarketingBanner.displayName = "MarketingBanner";
MarketingBanner.propTypes = propTypes;

export default MarketingBanner;
