import React from "react";
import { Loading } from "@evertrue/et-components";

const PhoneInput = React.lazy(() => import("components/phone-input"));

const LazyPhoneInput = props => {
  return (
    <React.Suspense fallback={<Loading />}>
      <PhoneInput {...props} />
    </React.Suspense>
  );
};

export default LazyPhoneInput;
