import _ from "underscore";
import classNames from "classnames";
import React from "react";
import { createComponent } from "@evertrue/et-flux";
import { Redirect } from "react-router-dom";
import { Checkbox, Loading } from "@evertrue/et-components";
import MfaStore from "apps/mfa/mfa-store";
import MfaSource from "apps/mfa/mfa-source";
import MobileUtils from "utils/mobile-utils";

export default createComponent("MfaController", {
  getInitialState() {
    return { input_code: "" };
  },

  registerStores() {
    this.on(MfaStore, () => ({
      phone_number: MfaStore.getPhoneNumber(),
      prompt_trust: MfaStore.getPromptTrust(),
      trust_device: MfaStore.getDeviceTrusted(),
      error_message: MfaStore.getError(),
      has_code_sent: MfaStore.hasCodeSent(),
      status: MfaStore.getStatus(),
    }));
  },

  componentWillMount() {
    // Initially set to loading so doesn't redirect to quickly
    if (!(this.state.has_code_sent && this.state.phone_number)) {
      this.setState({ status: "loading" });
    }
  },

  componentDidMount() {
    if (this.code_input != null) {
      this.code_input.focus();
    }

    const deviceId =
      !!window.location.search.match(/device_id=([^&\n]+)/) && window.location.search.match(/device_id=([^&\n]+)/)[1];
    const isMobile = !!MobileUtils.getDeviceOS() && MobileUtils.isMobile();

    if (!(this.state.has_code_sent && this.state.phone_number)) {
      if (isMobile && deviceId) {
        MfaSource.resend();
      } else {
        MfaSource.attemptFetchingPrompt();
      }
    }
  },

  handleSubmit() {
    MfaSource.submit(this.state.input_code);
  },

  handleResend() {
    MfaSource.resend();
  },

  handleTrustChange(isChecked) {
    MfaSource.setTrustDevice(!!isChecked);
  },

  handleChangeCode(event) {
    // strip non-numbers from the input and limit to six characters,
    // but allow pasting, etc
    this.setState({
      input_code: event.target.value.replace(/[^0-9]+/g, "").slice(0, 6),
    });
  },

  render() {
    const can_submit = _.size(this.state.input_code) === 6;
    const invalid_prompt_data = !this.state.phone_number;
    const message = `A text message with a verification code will be sent to: ${this.state.phone_number}`;

    return (
      <div className={`mfa is-${this.props.app}`}>
        <div className={`mfa--header is-${this.props.app}`}>
          {this.props.app !== "volunteers" && "2-Step Verification"}
          <img className={`mfa--header-phone-image is-${this.props.app}`} src="/images/iphone-code.png" alt="Phone" />
        </div>

        {(() => {
          if (this.state.status === "loading") {
            return <Loading />;
          } else if (invalid_prompt_data) {
            return <Redirect to={`/${this.props.app}/login/${this.props.slug || ""}`} />;
          }
          return null;
        })()}

        <div className={`mfa--content fs-exclude-data is-${this.props.app}`}>
          <div className="mfa--subtext"> {message} </div>
          <label className="mfa--text">
            {this.props.app !== "volunteers" ? "Please Enter Verification Code" : "Enter Code"}
            <input
              type="text"
              value={this.state.input_code}
              ref={el => {
                this.code_input = el;
              }}
              className={classNames("mfa--input", {
                "is-error": this.state.error_message,
              })}
              onKeyPress={e => {
                if (e.key === "Enter" && can_submit) this.handleSubmit();
              }}
              onChange={this.handleChangeCode}
            />
          </label>

          {this.state.error_message && <div className="mfa--error-text">{this.state.error_message}</div>}

          {this.state.prompt_trust && (
            <Checkbox
              className="mfa--trust-device"
              label="mfa--trust-device"
              checked={this.state.trust_device}
              onChange={this.handleTrustChange}
            >
              Trust this device
            </Checkbox>
          )}

          <button className="mfa--button btn" disabled={!can_submit} onClick={this.handleSubmit}>
            {this.state.status === "verifying" ? "Authenticating..." : "Verify"}
          </button>

          <div className="mfa--resend-link-area">
            {this.state.status === "resending" ? (
              "Sending new code"
            ) : (
              <a href="#resend" className="mfa--resend-link" onClick={this.handleResend}>
                Resend Code
              </a>
            )}
          </div>
        </div>
      </div>
    );
  },
});
