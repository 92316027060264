import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "@evertrue/et-components";

const MagicLinkSent = props => {
  return (
    <div className="magic-link-sent">
      {props.app === "volunteers" ? (
        <img alt="Volunteer Envelope" src="/images/envelope-icon.png" className="magic-link-sent--vol-envelope" />
      ) : (
        <Icon icon="success-circle" className="magic-link-sent--icon" />
      )}
      <div>Your access link is on its way!</div>
      <div>Please check your email.</div>
      <Button onClick={props.onPressOk} className={`magic-link-sent--btn is-${props.app}`}>
        OK
      </Button>
    </div>
  );
};

MagicLinkSent.propTypes = {
  app: PropTypes.string,
  onPressOk: PropTypes.func,
};

export default MagicLinkSent;
