import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import { Loading, Checkbox } from "@evertrue/et-components";
import { Redirect } from "react-router-dom";
import classNames from "classnames";
import MfaStore from "apps/mfa/mfa-store";
import LazyPhoneInput from "../../components/lazy-phone-input";
import OrgStore from "apps/org/org-store";
import Api from "utils/api";
import SessionStore from "apps/session/session-store";
import SafeRedirect from "../../components/safe-redirect";
import SessionSource from "apps/session/session-source";
import ErrorLogger from "utils/error-logger";

const CreateMfaController = props => {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [validNumber, setValidNumber] = React.useState(false);
  const [status, setStatus] = React.useState(MfaStore.getStatus());
  const [errorMessage, setErrorMessage] = React.useState(MfaStore.getError());
  const [trustDevice, setTrustDevice] = React.useState(true);
  const [promptData, setPromptData] = React.useState({});
  const [inputCode, setInputCode] = React.useState("");

  const redirectOnSuccess = () => {
    let rawRedirectUrl;
    if (!!props.redirect_url) {
      rawRedirectUrl = decodeURIComponent(props.redirect_url);
    } else {
      const env = window.location.host.split("-")[0];
      const envPrefix = env === "local" || env === "stage" ? "stage-" : "";

      rawRedirectUrl = `https://${envPrefix}accounts.evertrue.com/${props.app}/auth/redirect/${props.slug || ""}`;
    }
    window.location.replace(rawRedirectUrl);
  };

  const numericPhoneNumber = rawPhoneNumber => {
    return phoneNumber.replace(/[^0-9]+/g, "");
  };

  const handleSubmitPhoneNumber = phoneNumber => {
    if (phoneNumber.match(/[a-zA-Z]/)) {
      setErrorMessage("Phone number cannot contain letters");
      return;
    }

    submitPhoneNumber();
  };

  const handleSubmitCode = e => {
    e.preventDefault();
    submitCode();
  };

  const submitPhoneNumber = () => {
    setStatus("loading");

    Api.requestWithSession({
      type: "PUT",
      url: "auth/settings/mfa",
      data: { otp_medium: "sms", otp_mobile: `+${numericPhoneNumber(phoneNumber)}` },
      params: { oid: props.current_org.id },
      headers: {
        Authorization: props.session.token || props.mfa_token,
        "Authorization-Provider": "evertrueauthtoken",
        "Authorization-Auto-Send": 0,
        "Authorization-Multifactor": 1,
        "Authorization-Trust-Device": trustDevice ? 1 : 0,
      },
      error: xhr => {
        ErrorLogger.captureRequest("CreateMfaController", xhr);
        setStatus("error");
        setErrorMessage("There was an issue processing the provided phone number. Please try again.");
      },
    }).then(resp => {
      // mfa was not set, need to confirm
      setPromptData(resp);
      setStatus("success");
    });
  };

  const submitCode = () => {
    setStatus("loading");

    Api.requestWithSession({
      type: "PUT",
      url: "auth/settings/mfa",
      data: { otp_medium: "sms", otp_mobile: `+${numericPhoneNumber(phoneNumber)}` },
      params: { oid: props.current_org.id },
      headers: {
        Authorization: props.session.token || props.mfa_token,
        "Authorization-Provider": "evertrueauthtoken",
        "Authorization-Auto-Send": 0,
        "Authorization-Multifactor": 1,
        "Authorization-Otp": inputCode,
        "Authorization-Trust-Device": trustDevice ? 1 : 0,
      },
      error: xhr => {
        ErrorLogger.captureRequest("CreateMfaController", xhr);
        setStatus("error");
        setErrorMessage("There was an issue processing the provided verification code. Please try again.");
      },
    })
      .then(_ => {
        // mfa has been set, set session before redirect
        SessionSource.promise
          .createSessionWithHeaders({
            Authorization: props.session.token || props.mfa_token,
            "Authorization-Provider": "evertrueauthtoken",
            "Authorization-Auto-Send": 0,
            "Authorization-Multifactor": 1,
            "Authorization-Otp": inputCode,
            "Authorization-Trust-Device": trustDevice ? 1 : 0,
          })
          .then(_ => {
            // session has been set, redirect them to the app
            redirectOnSuccess();
            setStatus("success");
          })
          .catch(({ responseJSON = {}, responseText }) => {
            ErrorLogger.captureRequest("CreateMfaController", responseText);
            setStatus("error");
            setErrorMessage("There was an issue logging you in. Please try again.");
          });
      })
      .catch(({ responseJSON = {}, responseText }) => {
        ErrorLogger.captureRequest("CreateMfaController", responseText);
        setStatus("error");
        setErrorMessage("There was an issue processing the verification code. Please try again.");
      });
  };

  const handlePhoneChange = (formattedNumber, isValid) => {
    setPhoneNumber(formattedNumber);
    setValidNumber(isValid);
  };

  const handleResend = () => {
    submitPhoneNumber();
  };

  const handleTrustChange = e => {
    setTrustDevice(e);
  };

  const handleChangeCode = e => {
    e.preventDefault();
    setInputCode(e.target.value);
  };

  const canSubmit = inputCode && inputCode.length >= 6;
  const invalidPromptData = !phoneNumber;
  const message = `A text message with a verification code will be sent to: ${promptData.otp_mobile}`;
  const promptTrust = true;

  // make sure the org is set correctly
  React.useEffect(() => {
    if (!props.current_org || !props.current_org.slug === props.slug) {
      OrgStore.fetchOrgWithSlug(props.slug);
    }
  }, [props.slug, props.current_org]);

  if (!props.session.token && !props.mfa_token) {
    return <SafeRedirect to={`/${props.app}/login/${props.slug || ""}`} />;
  } else if (props.loading || status === "loading") {
    return <Loading />;
  } else if (!_.isEmpty(promptData)) {
    return (
      <div className={`mfa is-${props.app}`}>
        <div className={`mfa--header is-${props.app}`}>
          {props.app !== "volunteers" && "2-Step Verification"}
          <img className={`mfa--header-phone-image is-${props.app}`} src="/images/iphone-code.png" alt="Phone" />
        </div>

        {(() => {
          if (status === "loading") {
            return <Loading />;
          } else if (invalidPromptData) {
            return <Redirect to={`/${props.app}/login`} />;
          }
          return null;
        })()}

        <div className={`mfa--content fs-exclude-data is-${props.app}`}>
          <div className="mfa--subtext"> {message} </div>
          <label className="mfa--text">
            {props.app !== "volunteers" ? "Please Enter Verification Code" : "Enter Code"}
            <input
              type="text"
              className={classNames("mfa--input", {
                "is-error": errorMessage,
              })}
              onKeyPress={e => {
                if (e.key === "Enter" && canSubmit) handleSubmitCode(e);
              }}
              onChange={handleChangeCode}
            />
          </label>

          {errorMessage && <div className="mfa--error-text">{errorMessage}</div>}

          {promptTrust && (
            <Checkbox
              className="mfa--trust-device"
              label="mfa--trust-device"
              checked={trustDevice}
              onChange={handleTrustChange}
            >
              Trust this device
            </Checkbox>
          )}

          <button className="mfa--button btn" disabled={!canSubmit} onClick={handleSubmitCode}>
            {status === "verifying" ? "Authenticating..." : "Verify"}
          </button>

          <div className="mfa--resend-link-area">
            {status === "resending" ? (
              "Sending new code"
            ) : (
              <a href="#resend" className="mfa--resend-link" onClick={handleResend}>
                Resend Code
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`mfa is-${props.app}`}>
      <div className={`mfa--header is-${props.app}`}>
        {props.app !== "volunteers" && "2-Step Verification"}
        <img className={`mfa--header-phone-image is-${props.app}`} src="/images/iphone-code.png" alt="Phone" />
      </div>

      <div className={`mfa--content fs-exclude-data is-${props.app}`}>
        <div className="mfa--create-subtext">
          Two-step verification is required for your account. Please enter your mobile phone number below to receive
          your verification code.
        </div>
        <label className="mfa--text">
          Please Enter Verification Phone Number
          <LazyPhoneInput className="security-wizard--phone-input" onChange={handlePhoneChange} />
        </label>

        {errorMessage && <div className="mfa--error-text">{errorMessage}</div>}

        <button
          className="mfa--button btn"
          disabled={!validNumber}
          onClick={() => handleSubmitPhoneNumber(phoneNumber)}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {
    current_org: OrgStore.getCurrent(),
    session: SessionStore.getSession(),
    user: SessionStore.getUser(),
    loading: OrgStore.getLoading(),
    error: OrgStore.getError(),
    mfa_token: MfaStore.getMfaToken(),
  };
};

CreateMfaController.propTypes = {
  app: PropTypes.string,
  slug: PropTypes.string,
  redirect_url: PropTypes.string,
  // from the store
  current_org: PropTypes.object,
  session: PropTypes.object,
  user: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  mfa_token: PropTypes.string,
};

export default connect(CreateMfaController, [MfaStore, OrgStore, SessionStore], mapStateToProps);
