import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ForgotPasswordLink = props => {
  const { app } = props;
  return (
    <Link to={`/${app}/account/password/`} className="login--forgot">
      Forgot Password
    </Link>
  );
};

ForgotPasswordLink.propTypes = {
  app: PropTypes.string,
};

export default ForgotPasswordLink;
