/* eslint no-unreachable: 0 no-unused-vars: 0 */
import _ from "underscore";
import { createStore } from "@evertrue/et-flux";

const EMAIL_KEY = "et-accounts--email";

// TODO: remove `return false` statements once opt-in logic has been implemented to fix the email store integration. also remove eslint exceptions

function getEmailFromLocalStorage() {
  return false;
  return localStorage && localStorage.getItem(EMAIL_KEY);
}

function setEmailInLocalStorage(email) {
  return false;
  if (localStorage) {
    localStorage.setItem(EMAIL_KEY, email);
  }

  return email;
}

function resetEmailInLocalStorage() {
  return false;
  localStorage && localStorage.removeItem(EMAIL_KEY);
}

export default createStore("EmailStore", {
  getInitialState() {
    return {
      stored_email: getEmailFromLocalStorage(),
    };
  },

  api: {
    getEmail() {
      return false;
      const stored_email = getEmailFromLocalStorage();
      this.setState({ stored_email: stored_email });
      return stored_email || "";
    },

    setEmail(stored_email) {
      return false;
      setEmailInLocalStorage(stored_email);
      this.setState({ stored_email: stored_email });
      return stored_email;
    },

    resetEmail() {
      return false;
      resetEmailInLocalStorage();
      this.setState({ stored_email: "" });
      return "";
    },

    isEmailSet() {
      return false;
      const email = getEmailFromLocalStorage();
      return email && email !== "";
    },
  },
});
