import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import LinkedinAssociateSource from "apps/linkedin/linkedin-associate-source";
import Show from "components/show";
import { Icon, Loading } from "@evertrue/et-components";
import ErrorMessage from "components/error-message";

export default createComponent("LinkedinAssociateController", {
  propTypes: {
    app: PropTypes.string.isRequired,
    slug: PropTypes.string,
    params: PropTypes.object,
  },

  getDefaultProps() {
    return { params: {} };
  },

  getInitialState() {
    return { error: "" };
  },

  componentWillMount() {
    const { params } = this.props;
    if (params.code) {
      LinkedinAssociateSource.promise
        .fetchLinkedinAccessToken(params)
        .then(resp => LinkedinAssociateSource.associateUserWithLinkedinAccount(resp.linkedin_access_token));
    } else if (params.error) {
      this.setState({ error: "LinkedIn was unable to authenticate. Please try again." });
    }
  },

  handleConnect() {
    const { app, slug } = this.props;
    LinkedinAssociateSource.redirectToLinkedinAuth(app, slug);
  },

  render() {
    return (
      <div className="linkedin-associate-controller">
        <Show when={!!this.props.params.code}>
          <Loading />
        </Show>
        <Show when={!this.props.params.code}>
          <div>
            <Show when={!!this.state.error}>
              <ErrorMessage message={this.state.error} />
            </Show>
            <div className="login--header">
              Connect your LinkedIn account to get career and location info for your constituents!
            </div>
            <button className="login--linkedin-btn login--btn" onClick={this.handleConnect}>
              <Icon icon="linkedin-box" />
              Connect your LinkedIn
            </button>
          </div>
        </Show>
      </div>
    );
  },
});
