/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";

import Config from "config/env";

const Raven = window.Raven;
const additional_data = "";
// ACCOUNTS Sentry Project
const SENTRY_PATH = "https://8abf9a29d7224c46847d66600bfdd560@sentry.io/18520";
if (!Config.isDevelopment()) {
  __guardMethod__(
    typeof Raven !== "undefined" && Raven !== null
      ? Raven.config(SENTRY_PATH, {
          environment: Config.url_environment,
          tags: { data_environment: Config.environment_data },
          autoBreadcrumbs: { xhr: true, console: true, dom: true, location: true },
        })
      : undefined,
    "install",
    o => o.install()
  );
}

export default {
  send(message, data) {
    if (data == null) {
      data = {};
    }
    if (!(data.tags || data.extra) && !_.isEmpty(data)) {
      data = { extra: data };
    }

    if (!Config.isDevelopment()) {
      if (__guardMethod__(Raven, "isSetup", o1 => o1.isSetup())) {
        return Raven.captureMessage(message, data);
      }
      return console.warn(`(Sentry Error) - ${message}`, additional_data);
    }
  },

  error(message, data) {
    if (data == null) {
      data = {};
    }
    return this.send(message, data);
  },

  warn(message, data) {
    if (data == null) {
      data = {};
    }
    if (!(data.tags || data.extra)) {
      data = { extra: data };
    }
    return this.send(message, _.extend({ level: "warning" }, data));
  },

  captureRequest(message, xhr, meta_data) {
    if (meta_data == null) {
      meta_data = {};
    }
    if (meta_data.response) {
      console.warn("meta_data cannot contain 'response' property", meta_data);
    }

    return this.warn(`${message}`, {
      tags: {
        status_code: xhr != null ? xhr.status : undefined,
        status_text: xhr != null ? xhr.statusText : undefined,
      },
      extra: _.extend({}, meta_data, { response: xhr != null ? xhr.responseJSON : undefined }),
    });
  },
};

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== "undefined" && obj !== null && typeof obj[methodName] === "function") {
    return transform(obj, methodName);
  }
  return undefined;
}
