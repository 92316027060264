import React from "react";
import StatusIndicator from "apps/layout/status-indicator";
import PropTypes from "prop-types";
import AppLogos from "./app-logos";

const FooterLink = ({ url, children }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

FooterLink.propTypes = {
  children: PropTypes.any,
  url: PropTypes.string,
};
const AppFooter = ({ app }) => {
  return (
    <div className="app-footer">
      {app === "evertrue" && <AppLogos />}
      <StatusIndicator />|<FooterLink url="http://www.evertrue.com/privacy-policy">Privacy Policy</FooterLink>|
      <FooterLink url="http://www.evertrue.com/terms-and-conditions/">Terms of Use</FooterLink>|
      <FooterLink url="http://help.evertrue.com/">Support</FooterLink>
    </div>
  );
};

AppFooter.propTypes = {
  app: PropTypes.string,
};

AppFooter.displayName = "AppFooter";
export default AppFooter;
