import React from "react";
import PropTypes from "prop-types";
import MagicLinkButton from "components/magic-link-button";
import MagicLinkSent from "components/magic-link-sent";
import EmailPasswordLogin from "components/email-password-login";
import ForgotPasswordLink from "components/forgot-password-link";

const VolunteerLoginController = props => {
  const [magicLinkSent, setMagicLinkSent] = React.useState();

  if (magicLinkSent) {
    return <MagicLinkSent onPressOk={() => setMagicLinkSent(false)} app="volunteers" />;
  } else {
    return (
      <div className="login">
        <h2 className="login--header">Sign in to Continue</h2>

        <MagicLinkButton email={props.email} onSuccess={() => setMagicLinkSent(true)} />

        <div className="login--separator">
          <span className="login--separator-text"> or </span>
        </div>

        <EmailPasswordLogin email={props.email} device_id={props.device_id} />
        <ForgotPasswordLink app="volunteers" />
      </div>
    );
  }
};

VolunteerLoginController.propTypes = {
  email: PropTypes.string,
  device_id: PropTypes.string,
};

export default VolunteerLoginController;
