import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "utils/api";
import ErrorLogger from "utils/error-logger";
import { getAccessRoles } from "./roles-utils";

export default createSource("OrgSource", {
  actions: {
    set: true,
    error: true,
    loading: true,
    currentOrgLoading: true,
    fetchedOrgs(orgs) {
      return this.require(_.isArray(orgs), "orgs should be an array");
    },
    fetchedOrg(org) {
      return this.require(_.isObject(org), "org should be an object");
    },
    fetchedSsoData: true,
    reset: true,
  },

  fetchOrg(options) {
    this.actions.currentOrgLoading(true);
    const opts = _.extend({}, options, {
      success: resp => {
        this.actions.currentOrgLoading(false);
        this.actions.fetchedOrg(resp);
        if (resp.sso_method === "saml" && _.isObject(resp.identity_provider)) {
          const auth_url = resp.identity_provider.oauth2_authorize_url;
          const token_data = resp.identity_provider.token_data;
          this.actions.fetchedSsoData(auth_url, token_data);
        }
      },
      error: xhr => {
        this.actions.currentOrgLoading(false);
        ErrorLogger.captureRequest("OrgSource.fetchOrg", xhr);
      },
    });
    return Api.get(opts);
  },

  promise: {
    fetchOrgWithSlug(slug) {
      return this.fetchOrg({ url: `auth/organizations/slug/${slug}` });
    },

    fetchOrgWithId(id) {
      return this.fetchOrg({ url: `auth/organizations/${id}` });
    },
  },

  api: {
    set(org) {
      return this.actions.set(org);
    },

    reset() {
      return this.actions.reset();
    },

    setSsoData(auth_url, token_data) {
      this.actions.fetchedSsoData(auth_url, token_data);
    },

    fetchAll() {
      this.actions.loading(true);
      return Api.get({
        url: "auth/organizations",
        success: resp => {
          this.actions.loading(false);
          return this.actions.fetchedOrgs(resp);
        },
        error: xhr => {
          ErrorLogger.captureRequest("OrgSource.fetchAll", xhr);
          this.actions.loading(false);
          return this.actions.error(xhr != null ? xhr.responseJSON : undefined);
        },
      });
    },

    fetchForUser(user_object) {
      if (user_object != null && user_object.super_user) {
        return this.fetchAll();
      }
      return this.setFromUser(user_object);
    },

    // The user object that comes back with the user picker is different
    // than the standard and returns and array of organizations instead of affiliations.
    setFromUser({ affiliations = [], organizations = [] } = {} ) {
      const accessRoles = getAccessRoles(document.URL);
      let orgs;
      if (!!organizations.length) {
        orgs = organizations;
      } else {
        orgs = affiliations.reduce((filteredOrgs, affiliation) => {
          const roles = affiliation.affiliation_roles.map(r => r.role.name);
          return roles.some(role => accessRoles.includes(role)) ? 
          [...filteredOrgs, affiliation.organization] :
          filteredOrgs;
        }, []);
      }
      return this.actions.fetchedOrgs(orgs);
    },

    fetchOrgWithSlug(slug) {
      this.fetchOrg({ url: `auth/organizations/slug/${slug}` });
    },

    fetchOrgWithId(id) {
      this.fetchOrg({ url: `auth/organizations/${id}` });
    },
  },
}); 