import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import { Loading, Icon, Button } from "@evertrue/et-components";
import OrgSource from "apps/org/org-source";
import OrgStore from "apps/org/org-store";
import SsoStore from "apps/sso/sso-store";
import RegisterSource from "apps/register/register-source";
import Show from "components/show";
import AppsConfig from "config/apps-config";
import MagicLinkButton from "components/magic-link-button";
import MagicLinkSent from "components/magic-link-sent";
import AppRedirectSource from "apps/layout/app-redirect-source";
import Api from "utils/api";
import SetPasswordForm from "../../components/set-password-form";
import FormStatus from "components/form-status";
import SafeRedirect from "components/safe-redirect";
import { Link } from "react-router-dom";

export default createComponent("RegisterController", {
  propTypes: {
    app: PropTypes.string,
    slug: PropTypes.string,
    invite_token: PropTypes.string,
    device_id: PropTypes.string,
    params: PropTypes.object,
  },

  defaultProps: {
    device_id: null,
  },

  getInitialState() {
    return {
      email: "",
      password: "",
      magicLinkSent: false,
      disabled: true,
      displayText: "",
      passwordPolicy: {},
    };
  },

  registerStores() {
    this.on(SsoStore, () => ({
      sso_auth_url: SsoStore.getAuthUrl(),
    }));
    this.on(OrgStore, () => ({
      org_loading: OrgStore.getCurrentOrgLoading(),
    }));
  },

  componentDidMount() {
    const { params, invite_token, device_id, app, slug } = this.props;
    if (params && params.email) {
      RegisterSource.registerWithEmail(invite_token, params.email, device_id, undefined, undefined);
    }

    if (app !== "volunteers" && slug) {
      OrgSource.fetchOrgWithSlug(slug);
    }

    Api.get({
      url: `auth/registrations/password/policy?invitation_token=${invite_token}`,
      success: resp => {
        this.setState({
          email: resp.email,
          displayText: resp.display_text,
          passwordPolicy: resp.password_policy,
        });
      },
      error: xhr => {
        const error = xhr.responseJSON.message || "Unknown Error";
        this.setState({ error });
      },
    });
  },

  handleMagicLinkSuccess() {
    this.setState({ magicLinkSent: true });
  },

  handleConnect() {
    const { invite_token, params, app } = this.props;
    const data = _.extend({}, params, { invite_token });
    RegisterSource.beginRegisterWithLinkedin(data, app);
  },

  handleSubmit(_, password, passwordConfirmation) {
    RegisterSource.registerWithEmail(
      this.props.invite_token,
      this.state.email,
      this.props.device_id,
      password,
      passwordConfirmation
    );
  },

  render() {
    const { params = {} } = this.props;
    const { app, slug } = this.props;
    const login_types = AppsConfig.getLoginTypes(this.props.app);
    const has_li = _.contains(login_types, "LI");
    const has_evertrue = _.contains(login_types, "ET");
    const has_magic = _.contains(login_types, "Magic");
    const has_sso = _.contains(login_types, "SSO") && !!this.state.sso_auth_url;

    if (app !== "volunteers" && this.state.magicLinkSent) {
      return <SafeRedirect to={`/${app}/link-token-confirmation/${slug}?email=${this.state.email}`} />;
    }

    return app !== "volunteers" ? (
      <div className="register--wrapper">
        <div className="register">
          <h2 className="register--header">Welcome to EverTrue!</h2>
          <div className="register--subheader">Select your authentication method below.</div>

          <Show when={has_sso}>
            <Button
              type="secondary"
              className="register--email-btn register--btn"
              onClick={() => AppRedirectSource.redirectToApp(app)}
            >
              Return to app
            </Button>
          </Show>

          <Show when={has_li && !has_sso}>
            <Button className="login--linkedin-btn login--btn" onClick={this.handleConnect}>
              <Icon icon="linkedin-box" />
              Log In With LinkedIn
            </Button>
          </Show>

          <Show when={has_magic && !has_sso}>
            <Show when={has_li && has_magic}>
              <div className="login--separator">
                <span className="login--separator-text"> or </span>
              </div>
            </Show>

            {this.state.magicLinkSent ? (
              <MagicLinkSent
                onPressOk={() => {
                  this.setState({
                    magicLinkSent: false,
                  });
                }}
                app="evertrue"
              />
            ) : (
              <MagicLinkButton
                email={this.state.email}
                inviteToken={this.props.invite_token}
                disabled={false}
                app={this.props.app}
                slug={this.props.slug}
                onSuccess={this.handleMagicLinkSuccess}
              />
            )}
          </Show>

          <Show when={has_evertrue && !has_sso}>
            <div>
              <Show when={has_li || has_magic}>
                <div className="login--separator">
                  <span className="login--separator-text"> or </span>
                </div>
              </Show>

              <React.Fragment>
                <FormStatus status={this.state.status} message={this.state.message} />
                <SetPasswordForm
                  onSubmit={this.handleSubmit}
                  app={app}
                  passwordPolicy={this.state.passwordPolicy}
                  email={this.state.email}
                  displayText={this.state.displayText}
                  requireCurrentPassword={false}
                  submitButtonText="Submit"
                  type="create-account"
                  header={() => {
                    return (
                      <React.Fragment>
                        {this.state.email ? (
                          <p className="forgot-password--email-display">
                            Create Account for: <strong>{this.state.email}</strong>
                          </p>
                        ) : null}
                      </React.Fragment>
                    );
                  }}
                />

                {this.state.status === "error" && (
                  <div className="forgot-password--trouble">
                    <strong>Having Trouble?</strong>
                    <Link to="/forgot_password"> Click here to request a new link.</Link>
                  </div>
                )}
                {this.state.displayText && (
                  <div class="forgot-password--password-policy-text">
                    <strong>Note:</strong> {this.state.displayText}
                  </div>
                )}
              </React.Fragment>
            </div>
          </Show>
        </div>
      </div>
    ) : (
      <div className="linkedin-associate-controller">
        <Show when={!!params.email}>
          <Loading />
        </Show>
        <Show when={!params.email}>
          <div>
            <div className="login--header">Register for EverTrue with LinkedIn Authentication</div>
            <button className="login--linkedin-btn login--btn" onClick={this.handleConnect}>
              <Icon icon="linkedin-box" />
              Connect your LinkedIn
            </button>
          </div>
        </Show>
      </div>
    );
  },
});
