import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const SafeRedirect = props => {
  return (
    <Route
      render={({ location = {} }) => {
        if (!location.pathname.match(props.to)) {
          return <Redirect to={props.to} />;
        } else {
          return null;
        }
      }}
    />
  );
};

SafeRedirect.propTypes = {
  to: PropTypes.string,
};

export default SafeRedirect;
