import fundriver from "apps/images/fundriver.svg";
import impact from "apps/images/impact.svg";
import odder from "apps/images/odder.svg";
import pledgemine from "apps/images/pledgemine.svg";
import thankview from "apps/images/thankview.svg";
import volunteer from "apps/images/volunteer.svg";

import React from "react";

const AppLogos = () => {
  return (
    <div className="app-logos">
      <div className="app-logo volunteer-logo">
        <a href="https://accounts.evertrue.com/volunteers/login">
          <img alt="volunteer" src={volunteer} />
        </a>
      </div>
      <div className="app-logo">
        <a href="https://thankview.com/login">
          <img alt="thankview" src={thankview} />
        </a>
      </div>
      <div className="app-logo">
        <a href="https://secure.pledgemine.com/">
          <img alt="pledgemine" src={pledgemine} />
        </a>
      </div>
      <div className="app-logo">
        <a href="https://fundriver.com/login/">
          <img alt="fundriver" src={fundriver} />
        </a>
      </div>
      <div className="app-logo">
        <a href="https://fundriver.com/login/">
          <img alt="impact" src={impact} />
        </a>
      </div>
      <div className="app-logo">
        <a href="https://thankview.com/login">
          <img alt="odder" src={odder} />
        </a>
      </div>
    </div>
  );
};

export default AppLogos;
