/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";

const sandbox = "env_sandbox";

// Get URL prefix to determine environment
const url_parts = (() => {
  try {
    return window.location.host
      .split(".")
      .reverse()[2]
      .split("-");
  } catch (error) {}
})();
const url_environment = _.size(url_parts) > 1 ? url_parts[0] : "production";

// Environment data is based on localStorage first and url env second
const env = (localStorage && localStorage.getItem(sandbox)) || url_environment;

// Set the api url prefix based on environment
let api_prefix = env !== "production" ? "stage-api" : "api";

if (window.location.host.match("localhost")) {
  api_prefix = "stage-api";
}

export default {
  app_keys: {
    volunteers: "7cb9b8d4451567b725e81b998cdf46cfc58426f9936916c714acddbc9f082546",
  },
  apiBase: `https://${api_prefix}.evertrue.com/`,
  environment_data: env === "production" ? "Production" : "Stage",
  url_environment,
  isStageData() {
    return env !== "production";
  },
  isDevelopment() {
    return url_environment !== "production";
  },
  switchEnvironment() {
    if (env !== "production") {
      if (typeof localStorage !== "undefined" && localStorage !== null) {
        localStorage.setItem(sandbox, "production");
      }
    } else if (typeof localStorage !== "undefined" && localStorage !== null) {
      localStorage.removeItem(sandbox);
    }
    return window.location.reload();
  },
};
