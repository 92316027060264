import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import { Loading } from "@evertrue/et-components";
import FormStatus from "components/form-status";
import Api from "utils/api";
import SetPasswordForm from "../../components/set-password-form";
import SessionSource from "apps/session/session-source";
import SessionStore from "apps/session/session-store";

// Resets password using email and active session
const mapStateToProps = () => {
  return {
    session: SessionStore.getSession() || {},
  };
};

const ResetEmailPasswordController = props => {
  const { email, magic_token, device_id } = props;
  const [statusInfo, setStatusInfo] = React.useState({
    status: undefined,
    message: undefined,
    loading: false,
  });
  const { status, message, loading } = statusInfo;

  // if magic link, start login flow
  React.useEffect(() => {
    if (magic_token) {
      SessionSource.createSessionWithMagicToken(magic_token, device_id, email);
    }
  }, [device_id, email, magic_token]);

  const handleSubmit = password => {
    setStatusInfo({ status: undefined, message: undefined, loading: true });
    // if we have a session token, update email address using session
    if (!_.isEmpty(props.session)) {
      const headers = {
        "Authorization-Provider": "EvertrueAuthToken",
        Authorization: props.session.token,
      };
      Api.put({
        headers,
        url: "auth/registrations",
        data: {
          user: { email, password },
        },
      })
        .then(() => {
          SessionSource.setGoToResetEmailPassword();
        })
        .catch(({ responseJSON = {} }) => {
          const errors = responseJSON.errors || {};
          const password_errors = _.isArray(errors.password) ? errors.password : [];
          setStatusInfo({ status: "error", message: password_errors[0], loading: false });
        });
    }
  };

  return (
    <div className="forgot-password">
      {loading ? <Loading /> : null}

      {magic_token ? (
        <Loading />
      ) : (
        <React.Fragment>
          <FormStatus status={status} message={message} />

          <SetPasswordForm
            onSubmit={handleSubmit}
            header={() => {
              return (
                <React.Fragment>
                  <h2>Create or reset a password</h2>
                  {!!email && (
                    <div className="forgot-password--email">
                      Sign in as: <bold>{email}</bold>
                    </div>
                  )}
                </React.Fragment>
              );
            }}
            submitButtonText="Set Password and Login"
          />
        </React.Fragment>
      )}
    </div>
  );
};

ResetEmailPasswordController.propTypes = {
  session: PropTypes.object,
  magic_token: PropTypes.string,
  email: PropTypes.string,
  device_id: PropTypes.string,
};

export default connect(ResetEmailPasswordController, [SessionStore], mapStateToProps);
