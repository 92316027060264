import React from "react";
import PropTypes from "prop-types";
import { Button } from "@evertrue/et-components";

const propTypes = {
  type: PropTypes.string,
  email: PropTypes.string,
  handleSubmit: PropTypes.func,
};

const defaultProps = {
  type: "",
  email: "",
  handleSubmit: () => {
    return "";
  },
};

const Confirmation = props => {
  const { type, email, handleSubmit } = props;
  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [buttonText, setButtonText] = React.useState("");
  const [includeEmail, setIncludeEmail] = React.useState("");

  React.useEffect(() => {
    switch (type) {
      case "link-token":
        setTitle("Email Link Sent!");
        setText("An email with your access link has been sent to: ");
        setButtonText("Return to Login");
        setIncludeEmail(true);
        break;
      case "forgot-password":
        setTitle("Forgot Password");
        setText("Your password has been successfully changed for: ");
        setButtonText("Return to App");
        setIncludeEmail(true);
        break;
      case "change-password":
        setTitle("Change Password");
        setText("Your password has been successfully changed for: ");
        setButtonText("Return to App");
        setIncludeEmail(true);
        break;
      case "forgot-password-email":
        setTitle("Forgot Password Email Sent!");
        setText("Please check your email to reset your password.");
        setButtonText("Return to Login");
        setIncludeEmail(false);
        break;
      default:
      // use defaults
    }
  }, [type]);

  const emailText = includeEmail ? (
    <div>
      {text}
      <strong>{email}</strong>
    </div>
  ) : (
    <div>{text}</div>
  );

  return (
    <div className="confirmation">
      <div className="confirmation--wrapper">
        <div className="confirmation--title">{title}</div>
        <div className="confirmation--img"></div>
        <div className="confirmation--text">{emailText}</div>
        <Button onClick={handleSubmit.bind(this)}>{buttonText}</Button>
      </div>
    </div>
  );
};

Confirmation.displayName = "Confirmation";
Confirmation.propTypes = propTypes;
Confirmation.defaultProps = defaultProps;

export default Confirmation;
