import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { Loading } from "@evertrue/et-components";
import FormStatus from "components/form-status";
import Api from "utils/api";
import SetPasswordForm from "../../components/set-password-form";
import SessionSource from "apps/session/session-source";

// This file is currently used for volunteers to register a new user and set a password
const RegisterEmailPasswordController = props => {
  const { email, device_id, invite_token } = props;
  const [statusInfo, setStatusInfo] = React.useState({
    status: undefined,
    message: undefined,
    loading: false,
  });
  const { status, message, loading } = statusInfo;

  const updatePassword = (email, password, token) => {
    const headers = {
      "Authorization-Provider": "EvertrueAuthToken",
      Authorization: token,
    };
    if (device_id) {
      headers["Device-ID"] = device_id;
    }
    return Api.put({
      headers,
      url: "auth/registrations",
      data: {
        user: { email, password },
      },
    });
  };

  const handleSubmit = password => {
    setStatusInfo({ status: undefined, message: undefined, loading: true });

    if (invite_token) {
      const headers = {};
      if (device_id) {
        headers["Device-ID"] = device_id;
      }
      Api.post({
        headers,
        url: "auth/registrations",
        data: {
          user: { email, invite_token },
        },
      })
        .then((resp = {}) => {
          const session = resp.session;

          // we aren't setting the password in the auth/registrations
          // step because if the user is already an ET user, and then
          // they get invited to volunteers the password setting would fail here
          // because they already have a user
          updatePassword(email, password, session.token)
            .then(() => {
              SessionSource.createdSession(session);
            })
            .catch(({ responseJSON = {} }) => {
              const errors = responseJSON.errors || {};
              const password_errors = _.isArray(errors.password) ? errors.password : [];
              setStatusInfo({ status: "error", message: password_errors[0], loading: false });
            });
        })
        .catch(({ responseJSON = {} }) => {
          let message = responseJSON.message || responseJSON.error;
          setStatusInfo({ status: "error", message: message, loading: false });
        });
    }
  };

  return (
    <div className="forgot-password">
      {loading ? <Loading /> : null}

      <React.Fragment>
        <FormStatus status={status} message={message} />

        <SetPasswordForm
          onSubmit={handleSubmit}
          header={() => {
            return (
              <React.Fragment>
                <h2>Create a password</h2>
                {!!email && (
                  <div className="forgot-password--email">
                    Sign in as: <bold>{email}</bold>
                  </div>
                )}
              </React.Fragment>
            );
          }}
          submitButtonText="Set Password and Login"
        />
      </React.Fragment>
    </div>
  );
};

RegisterEmailPasswordController.propTypes = {
  invite_token: PropTypes.string,
  email: PropTypes.string,
  device_id: PropTypes.string,
};

export default RegisterEmailPasswordController;
