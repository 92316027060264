import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import SessionSource from "apps/session/session-source";
import LinkedinLoginSource from "apps/linkedin/linkedin-login-source";
import RegisterSource from "apps/register/register-source";
import OrgSource from "apps/org/org-source";
import MfaSource from "apps/mfa/mfa-source";
import UserSource from "apps/user/user-source";
import MobileUtils from "utils/mobile-utils";
import Api from "utils/api";
import cookies from "utils/cookies";

export default createStore("SessionStore", {
  getInitialState() {
    return {
      session: {},
      refresh_token: undefined,
      posting_to_mobile: false,
      renew_attempted: false,
      reset_email_password: false,
    };
  },

  registerActions() {
    this.on(SessionSource.actions.createdSession, this.respondToCreateSession);
    this.on(SessionSource.actions.createdSessionWithEmail, this.respondToCreateSession);
    this.on(SessionSource.actions.createdSessionWithLidsToken, this.respondToCreateSession);
    this.on(SessionSource.actions.createdSessionWithCookie, this.respondToCreateSession);
    this.on(SessionSource.actions.createdSessionWithMagicToken, this.respondToCreateSession);
    this.on(SessionSource.actions.createdSessionWithHeaders, this.respondToCreateSession);
    this.on(SessionSource.actions.createdSessionWithSsoTokens, this.respondToCreateSession);
    this.on(SessionSource.actions.setSession, this.respondToSetSession);
    this.on(SessionSource.actions.setIsPostingToMobile, posting_to_mobile => {
      this.setState({ posting_to_mobile });
    });

    this.on(SessionSource.actions.renewAttempted, () => {
      this.setState({ renew_attempted: true });
    });

    this.on(SessionSource.actions.setGoToResetEmailPassword, email => {
      this.setState({ reset_email_password: email });
    });

    this.on(MfaSource.actions.createdSessionViaMfaAttempt, this.respondToCreateSession);

    this.on(RegisterSource.actions.beginRegisterWithLinkedin, this.respondToBeginRegisterWithLinkedin);
    this.on(RegisterSource.actions.registeredWithLinkedin, this.respondToRegisteredWithLinkedin);
    this.on(RegisterSource.actions.registeredWithEmail, this.respondToCreateSession);

    return this.on(LinkedinLoginSource.actions.activatedLinkedInLogin, token => {
      const device_id = MobileUtils.isMobile() ? MobileUtils.getDeviceIdFromStorage() : undefined;
      SessionSource.createSessionWithLidsToken(null, token, device_id);
    });
  },

  respondToCreateSession(data, refresh_token) {
    const is_user_picker = _.isArray(data);
    if (is_user_picker) {
      this.setState({
        user_options: data,
        refresh_token,
      });
    } else {
      UserSource.setCurrentUser(data.user);
      this.setState({
        refresh_token: null,
      });

      // if not super user
      if (!(data != null ? data.user.super_user : undefined)) {
        const orgs = _.compact(_.uniq(_.pluck(data.user != null ? data.user.affiliations : undefined, "organization")));
        if ((orgs != null ? orgs.length : undefined) === 1) {
          const current_org = _.first(orgs);
          OrgSource.set(current_org);
          OrgSource.setFromUser(data.user);
        } else {
          OrgSource.setFromUser(data.user);
        }
      }
    }

    this.respondToSetSession(data);
  },

  respondToSetSession(session) {
    // Send session to mobile
    Api.setSession(session.token);
    this.setState({ session });

    if (session.token) {
      cookies.storeToken(session.token);
    }
  },

  respondToBeginRegisterWithLinkedin(params, app) {
    LinkedinLoginSource.redirectToAuthUrl(params, app);
  },

  respondToRegisteredWithLinkedin(user_id, li_token) {
    const device_id = MobileUtils.isMobile() ? MobileUtils.getDeviceIdFromStorage() : undefined;
    SessionSource.createSessionWithLidsToken(user_id, li_token, device_id);
  },

  api: {
    getSession() {
      return this.getState("session");
    },

    getUser() {
      const session = this.getSession() || {};
      return session.user;
    },

    getUserOptions() {
      return this.getState("user_options");
    },

    getRefreshToken() {
      return this.getState("refresh_token");
    },

    getIsPostingToMobile() {
      return this.getState("posting_to_mobile");
    },

    getRenewAttempted() {
      return this.getState("renew_attempted");
    },

    getResetEmailPassword() {
      return this.state.reset_email_password;
    },
  },
});
