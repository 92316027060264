import React from "react";
import backgroundImage from "apps/images/et-login-left-banner.svg";
import signalImage from "apps/images/signal-graphic.svg";

const AppLeftBanner = () => {
  return (
    <div
      className="app-layout--left-banner"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover" }}
    >
      <div className="app-layout--left-banner__content">
        <div className="text-top">
          REVOLUTIONIZE YOUR<br></br> FUNDRAISING SHOP WITH
        </div>
        <img src={signalImage} alt="Signal Login Banner"></img>
        <div className="text-bottom">Connect with your account team to learn more!</div>
      </div>
    </div>
  );
};

export default AppLeftBanner;
