import _ from "underscore";
import Config from "config/env";

const FullStory = window.FS;
const env = Config.isStageData() ? "stage" : "production";

export default {
  setUser(user) {
    if (_.isObject(user) && FullStory) {
      FullStory.identify(user.id, {
        displayName: user.name,
        email: user.email,
        internalUser_bool: user.super_user,
        environment_str: env,
      });
    }
  },
};
