import _ from "underscore";
import $ from "jquery";
import Api from "utils/api";
import Config from "config/env";
import { createSource } from "@evertrue/et-flux";
import RegisterSource from "apps/register/register-source";

export default createSource("LoginSource", {
  actions: {
    activatedLinkedInLogin(token) {
      this.require(!_.isUndefined(token), "linkedin token must be defined");
    },
    loginError(method_name) {
      this.require(_.isString(method_name), "method_name must be a string");
    },
  },

  api: {
    redirectToAuthUrl(params, app, slug) {
      const query_string = params ? `?${$.param(params)}` : "";

      let base_route = `${window.location.origin}/${app}`;

      base_route += "/login/linkedin/process";
      if (slug) {
        base_route += `/${slug}`;
      }

      Api.get({
        url: "lids/auth",
        params: {
          callback_url: `${base_route}${query_string}`,
          verify: Config.url_environment === "dev" ? 1 : 0,
        },
        success: resp => {
          if (resp.authorize_url) {
            _.defer(() => {
              window.location = resp.authorize_url;
            });
          }
        },
        error: xhr => {
          this.actions.loginError("redirectToAuthUrl", { xhr });
        },
      });
    },

    authenticateWithLinkedIn(params) {
      if (params.error || !(params.code && params.state)) {
        this.actions.loginError("authenticateWithLinkedIn", { linkedin_params: params });
        return null;
      }

      Api.get({
        url: "lids/callback",
        params,
        success: resp => {
          if (params.invite_token) {
            RegisterSource.registerWithLinkedin(params.invite_token, resp.linkedin_access_token);
          } else {
            this.activateLinkedInAuth(resp.linkedin_access_token);
          }
        },
        error: xhr => {
          this.actions.loginError("authenticateWithLinkedIn", { xhr });
        },
      });

      return null;
    },

    activateLinkedInAuth(token) {
      Api.put({
        url: "lids/auth/association/activate",
        params: { linkedin_access_token: token },
        headers: {
          "Authorization-Auto-Send": 0,
          "Authorization-Multifactor": 1,
        },
        success: () => this.actions.activatedLinkedInLogin(token),
        error: xhr => this.actions.loginError("activateLinkedInAuth", { xhr }),
      });
    },
  },
});
