import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import { Loading } from "@evertrue/et-components";
import { Redirect } from "react-router-dom";
import SessionSource from "apps/session/session-source";
import OrgSource from "apps/org/org-source";

export default createComponent("MagicLoginController", {
  propTypes: {
    app: PropTypes.string,
    slug: PropTypes.string,
    token: PropTypes.string,
    device_id: PropTypes.string,
  },

  defaultProps: {
    app: null,
    slug: null,
    token: null,
    device_id: null,
  },

  getInitialState() {
    return {
      org: {},
      redirect_to_saml: false,
    };
  },

  componentDidMount() {
    // check if the org is a sso org before assuming it should be using magic token
    const { slug, params = {}, token, device_id } = this.props;

    if (slug) {
      OrgSource.promise
        .fetchOrgWithSlug(slug)
        .then(resp => this.checkOrgSsoMethod(resp, token, device_id))
        .catch(() => this.startSessionWithMagicToken(token, device_id));
    } else if (params.oid) {
      OrgSource.promise
        .fetchOrgWithId(params.oid)
        .then(resp => this.checkOrgSsoMethod(resp, token, device_id))
        .catch(() => this.startSessionWithMagicToken(token, device_id));
    } else {
      this.startSessionWithMagicToken(token, device_id);
    }
  },

  checkOrgSsoMethod(org = {}, token, device_id) {
    if (org.sso_method === "saml") {
      this.setState({ org, redirect_to_saml: true });
    } else {
      this.startSessionWithMagicToken(token, device_id);
    }
  },

  startSessionWithMagicToken(token, device_id) {
    _.defer(() => {
      SessionSource.createSessionWithMagicToken(token, device_id);
    });
  },

  render() {
    const { redirect_to_saml, org } = this.state;
    if (redirect_to_saml) {
      return <Redirect to={`/${this.props.app}/login/${org.slug}`} />;
    }
    return <Loading />;
  },
});
