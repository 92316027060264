/* eslint jsx-a11y/anchor-is-valid: 0 */

import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import SessionSource from "apps/session/session-source";
import OrgSource from "apps/org/org-source";
import OrgStore from "apps/org/org-store";
import SsoStore from "apps/sso/sso-store";
import SessionStore from "apps/session/session-store";
import EmailStore from "apps/login/email-store";
import { Icon, Loading, Button } from "@evertrue/et-components";
import { Link } from "react-router-dom";
import LinkedinLoginSource from "apps/linkedin/linkedin-login-source";
import AppRedirectSource from "apps/layout/app-redirect-source";
import AppsConfig from "config/apps-config";
import Show from "components/show";
import MobileUtils from "utils/mobile-utils";
import SafeRedirect from "components/safe-redirect";
import MagicLinkButton from "components/magic-link-button";
import MagicLinkSent from "components/magic-link-sent";
import Confirmation from "components/confirmation";
import ErrorLogger from "utils/error-logger";
import ErrorMessage from "../../components/error-message";

export default createComponent("LoginController", {
  propTypes: {
    app: PropTypes.string.isRequired,
    slug: PropTypes.string,
    params: PropTypes.object,
  },

  getInitialState() {
    let hasEmailStoreMatch = EmailStore.getEmail() && EmailStore.getEmail().match(/.+@.+\..+/gi);
    let hasEmailParamMatch =
      this.props.params && this.props.params.email && this.props.params.email.match(/.+@.+\..+/gi);

    return {
      email: EmailStore.getEmail() || this.props.params.email,
      password: "",
      magicLinkSent: false,
      disabled: !hasEmailStoreMatch && !hasEmailParamMatch,
      statusInfo: { status: "success", message: "" },
      loading: false,
    };
  },

  registerStores() {
    this.on(SsoStore, () => ({
      sso_auth_url: SsoStore.getAuthUrl(),
    }));
    this.on(OrgStore, () => ({
      org_loading: OrgStore.getCurrentOrgLoading(),
    }));
    this.on(SessionStore, () => ({
      session: SessionStore.getSession(),
    }));
    this.on(EmailStore, () => ({
      stored_email: EmailStore.getEmail(),
    }));
  },

  componentDidMount() {
    if (this.props.slug) {
      OrgSource.fetchOrgWithSlug(this.props.slug);
    } else {
      OrgSource.reset();
    }

    if (this.props.params && this.props.params.device_id) {
      MobileUtils.storeDeviceId(this.props.params.device_id);
    }
  },

  setStatusInfo(statusInfo) {
    this.setState({ statusInfo });
  },

  handleLinkedinLogin() {
    LinkedinLoginSource.redirectToAuthUrl(null, this.props.app, this.props.slug);
  },

  handleEmailLogin() {
    this.setStatusInfo({ status: "pending", message: "" });

    if (!EmailStore.isEmailSet()) {
      EmailStore.setEmail(this.state.email);
    }

    if (this.props.app === "volunteers") {
      SessionSource.createSessionWithEmail(
        this.state.email,
        this.state.password,
        this.props.params.device_id || MobileUtils.getDeviceIdFromStorage()
      );
    } else {
      let deviceId = undefined;

      if (
        !!(
          !!MobileUtils.getDeviceOS() &&
          MobileUtils.isMobile() &&
          (this.props.params.device_id || MobileUtils.getDeviceIdFromStorage())
        )
      ) {
        deviceId = this.props.params.device_id || MobileUtils.getDeviceIdFromStorage();
      }

      SessionSource.promise
        .createSessionPromiseWithEmail(this.state.email, this.state.password, deviceId)
        .then(() => this.setStatusInfo({ status: "success", message: "" }))
        .catch(({ responseJSON = {}, responseText }) => {
          ErrorLogger.captureRequest("LoginController", responseText);
          const message = responseJSON.message || responseJSON.error || "Invalid email and/or password.";
          this.setStatusInfo({ status: "error", message: message });
        });
    }
  },

  handleResetEmail() {
    EmailStore.resetEmail();
    this.setState({
      email: "",
      disabled: true,
    });
  },

  handleEmailInputChange(e) {
    if (e.target.value.match(/.+@.+\..+/gi)) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }

    this.setState({ email: e.target.value });
  },

  handlePasswordInputChange(e) {
    this.setState({ password: e.target.value });
  },

  handleMagicLinkSuccess() {
    EmailStore.setEmail(this.state.email);
    this.setState({ magicLinkSent: true });
  },

  handleSubmitOnEnter(e) {
    if (e.keyCode === 13) {
      this.handleEmailLogin(e);
    }
  },

  render() {
    const login_types = AppsConfig.getLoginTypes(this.props.app);
    const has_li = _.contains(login_types, "LI");
    const has_evertrue = _.contains(login_types, "ET");
    const has_magic = _.contains(login_types, "Magic");
    const has_sso = _.contains(login_types, "SSO") && !!this.state.sso_auth_url;
    const login_header = AppsConfig.getProp(this.props.app, "login_header");
    const referred_from_email = this.props.params && this.props.params.referrer === "invite_email";
    const is_mobile = !!MobileUtils.getDeviceOS() && MobileUtils.isMobile();

    // We should be encouraging mobile app sign in if user is on a device (android or ios && isMobile)
    // and is not already in the app on a webview (no device_id)
    const should_choose_mobile_app =
      (!this.props.params || !this.props.params.device_id) && is_mobile && AppsConfig.getHasMobileLinks(this.props.app);
    const has_token = !!this.state.session.token;

    if (has_sso && referred_from_email) {
      window.location = this.state.sso_auth_url;
      return <Loading />;
    } else if (should_choose_mobile_app && !has_token) {
      return <SafeRedirect to={`/${this.props.app}/mobile`} />;
    } else if (this.state.org_loading) {
      return <Loading />;
    } else if (has_sso && this.state.sso_auth_url && this.state.sso_auth_url !== "") {
      window.location.replace(this.state.sso_auth_url);
      return <Loading />;
    } else if (has_sso) {
      return <Loading />;
    } else if (this.state.magicLinkSent && this.props.app !== "volunteers") {
      return (
        <Confirmation
          email={this.state.email}
          type="link-token"
          handleSubmit={() => this.setState({ magicLinkSent: false })}
        />
      );
    }

    return (
      <div className="login--wrapper">
        <div className="login">
          <Show when={this.state.statusInfo.status === "pending"}>
            <Loading />
          </Show>

          <Show when={!!login_header}>
            <h2 className="login--header"> {login_header} </h2>
          </Show>

          <Show when={!has_li && !has_evertrue && !has_sso}>
            <Button
              type="secondary"
              className="login--email-btn login--btn"
              onClick={() => AppRedirectSource.redirectToApp(this.props.app)}
            >
              Return to app
            </Button>
          </Show>

          <Show when={has_sso}>
            <a className="login--email-btn login--btn" href={this.state.sso_auth_url}>
              Log In With Your Organization
            </a>
          </Show>

          <Show when={has_li && !has_sso}>
            <Button className="login--linkedin-btn login--btn" onClick={this.handleLinkedinLogin}>
              <Icon icon="linkedin-box" />
              Log In With LinkedIn
            </Button>

            <Show when={this.props.params && this.props.params.li_error}>
              <ErrorMessage message={this.props.params.li_error} />
            </Show>
          </Show>

          <Show when={has_magic && !has_sso}>
            <Show when={has_li && has_magic}>
              <div className="login--separator">
                <span className="login--separator-text"> or </span>
              </div>
            </Show>

            {this.state.magicLinkSent ? (
              <MagicLinkSent
                onPressOk={() => {
                  this.setState({
                    magicLinkSent: false,
                  });
                }}
                app="evertrue"
              />
            ) : (
              <MagicLinkButton
                email={this.state.email}
                disabled={this.state.disabled}
                app={this.props.app}
                slug={this.props.slug}
                deviceId={this.props.params.device_id || MobileUtils.getDeviceIdFromStorage()}
                onSuccess={this.handleMagicLinkSuccess}
              />
            )}
          </Show>

          <Show when={has_evertrue && !has_sso}>
            <div>
              <Show when={has_li || has_magic}>
                <div className="login--separator">
                  <span className="login--separator-text"> or </span>
                </div>
              </Show>
              {EmailStore.isEmailSet() ? (
                <div className="fs-exclude-data login--current-email">
                  Sign-in as:
                  <strong>{EmailStore.getEmail()}</strong>
                  <a href="#" onClick={this.handleResetEmail}>
                    Change
                  </a>
                </div>
              ) : this.props.app !== "volunteers" &&
                this.props.params &&
                this.props.params.email &&
                this.props.params.email.match(/.+@.+\..+/gi) ? (
                <div>
                  <div className="fs-exclude-data login--current-email">
                    Sign-in as:
                    <strong>{this.props.params.email}</strong>
                  </div>
                  <div className="login--change-email">
                    {this.props.app !== "volunteers" && (
                      <Link
                        to={`/${this.props.app}/enter-email?email=${
                          (this.state.email && encodeURIComponent(this.state.email)) || ""
                        }${
                          this.props.params.device_id || MobileUtils.getDeviceIdFromStorage()
                            ? `&device_id=${this.props.params.device_id || MobileUtils.getDeviceIdFromStorage()}`
                            : ""
                        }`}
                        className="login--change-email"
                      >
                        Change Email
                      </Link>
                    )}
                  </div>
                </div>
              ) : (
                <input
                  onChange={this.handleEmailInputChange}
                  type="text"
                  defaultValue={this.state.email}
                  placeholder="Email Address"
                  id="EmailAddress"
                />
              )}
              <input
                className="fs-exclude-data"
                id="Password"
                onChange={this.handlePasswordInputChange}
                type="password"
                placeholder="Password"
                onKeyDown={this.handleSubmitOnEnter}
              />
              {this.state.error}
              <Show when={this.state.statusInfo.status === "error"}>
                <ErrorMessage message={this.state.statusInfo.message} />
              </Show>
              <Button
                className="login--email-btn login--btn login-email-color"
                disabled={this.state.disabled}
                onClick={this.handleEmailLogin}
              >
                Log In With Email
              </Button>
              <Link
                to={`/${this.props.app}/account/password/${this.props.slug || ""}?email=${
                  this.state.email ? encodeURIComponent(this.state.email) : ""
                }`}
                className="login--forgot"
              >
                Forgot Password
              </Link>
            </div>
          </Show>
        </div>
      </div>
    );
  },
});
