import React from "react";
import _ from "underscore";
import { render } from "react-dom";
import AppRouteController from "./scripts/app-route-controller";
import { BrowserRouter } from "react-router-dom";

// some dep uses Array.from, shim for IE 11
import "babel-polyfill";
// React 16 shims
import $ from "jquery";

import "./styles/index.css";

// React 16 shims
require("raf").polyfill();

window.EverTrue = window.EverTrue || {};

window.EverTrue.test = () => {
  const reload = () => {
    const redirect = encodeURIComponent("https://stage-app.evertrue.com");
    const url = "http://local-accounts.evertrue.com:3016/evertrue/login?redirect=";
    window.location = url + redirect;
  };

  $.ajax({
    url: "https://stage-api.evertrue.com/skiff",
    type: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    },
    xhrFields: {
      withCredentials: true,
    },
  })
    .then(reload)
    .catch(reload);
};

if (!window.location.origin) {
  // Some browsers (mainly IE) does not have this property, so we need to build it manually...
  window.location.origin =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");
}

_.mixin({
  compactObject(object) {
    const obj = {};
    _.each(object, (value, key) => {
      if (!_.isUndefined(value) && !_.isNull(value)) {
        obj[key] = value;
      }
    });
    return obj;
  },
});

render(
  <BrowserRouter>
    <AppRouteController />
  </BrowserRouter>,
  document.getElementById("region-page")
);
