import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import SessionSource from "apps/session/session-source";
import SsoSource from "apps/sso/sso-source";
import SsoStore from "apps/sso/sso-store";
import { Loading } from "@evertrue/et-components";
import MobileUtils from "utils/mobile-utils";

export default createComponent("SsoLoginController", {
  propTypes: {
    params: PropTypes.object,
  },

  registerStores() {
    this.on(SsoStore, () => ({
      sso_data: SsoStore.getSsoData(),
    }));
  },

  componentDidMount() {
    const { params } = this.props;
    const code_is_defined = params && params.code && params.code !== "undefined";

    if (code_is_defined && !_.isEmpty(this.state.sso_data)) {
      const device_id = MobileUtils.isMobile() ? MobileUtils.getDeviceIdFromStorage() : undefined;
      SsoSource.promise.fetchAccessToken(params.code, this.state.sso_data).then(tokens => {
        SessionSource.createSessionWithSsoTokens(tokens, device_id);
      });
    } else if (params && params.error) {
      const error_data = _.pick(params, "error", "error_description");
      SsoSource.setDeferredError("sso-login: error", error_data);
    } else {
      let error_description = "";
      if (!code_is_defined) {
        error_description += "No access token provided; ";
      }
      if (_.isEmpty(this.state.sso_data)) {
        error_description += "No SSO data provided; ";
      }
      SsoSource.setDeferredError("sso-login: missing data", { error_description });
    }
  },

  render() {
    return (
      <div className="sso-login-controller">
        <Loading />
      </div>
    );
  },
});
