import { createStore } from "@evertrue/et-flux";
import UserSource from "apps/user/user-source";
import LinkedinAssociateSource from "apps/linkedin/linkedin-associate-source";
import AnalyticsUtils from "utils/analytics";
import MobileUtils from "utils/mobile-utils";

export default createStore("UserStore", {
  getInitialState() {
    return {
      user: {},
      has_li_association: false,
    };
  },

  registerActions() {
    this.on(UserSource.actions.setUser, this.respondToNewUser);

    this.on(LinkedinAssociateSource.actions.associatedUserWithLinkedinAccount, this.setUserAssociatedWithLinkedin);
  },

  respondToNewUser(user) {
    AnalyticsUtils.setUser(user);
    this.setState({ user });
    if (user.has_linkedin_identities) {
      this.setUserAssociatedWithLinkedin();
    }
  },

  setUserAssociatedWithLinkedin() {
    const has_li_association = true;
    this.setState({ has_li_association });

    if (MobileUtils.isMobile()) {
      MobileUtils.postMessage({ has_li_association });
    }
  },

  api: {
    getUser() {
      return this.getState("user");
    },
    getUserHasLinkedinAssociation() {
      return this.getState("has_li_association");
    },
  },
});
