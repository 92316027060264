import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { Loading, Button } from "@evertrue/et-components";
import FormStatus from "components/form-status";
import Api from "utils/api";
import ErrorLogger from "utils/error-logger";
import SetPasswordForm from "../../components/set-password-form";
import SessionStore from "apps/session/session-store";
import OrgStore from "apps/org/org-store";
import OrgSource from "apps/org/org-source";
import AppsConfig from "config/apps-config";
import { connect } from "@evertrue/et-flux";
import Confirmation from "components/confirmation";
import SessionSource from "apps/session/session-source";
import Config from "config/env";

const mapStateToProps = () => {
  return {
    user: SessionStore.getUser() || {},
    org: OrgStore.getCurrent() || {},
  };
};

// Reset password using a reset password token
// doesn't need email or session
const ResetPasswordController = props => {
  const { app, slug, type } = props;
  const [statusInfo, setStatusInfo] = React.useState({
    status: undefined,
    message: undefined,
    loading: false,
  });
  const { status, message, loading } = statusInfo;

  const [passwordPolicyInfo, setPasswordPolicyInfo] = React.useState({
    email: "",
    passwordPolicyText: "",
    passwordRequirements: [],
  });
  const [redirect, setRedirect] = React.useState(false);
  const { email, passwordPolicyText } = passwordPolicyInfo;
  React.useEffect(() => {
    setStatusInfo({ status: undefined, message: undefined, loading: true });

    if (type === "change-password") {
      const user = props.user;
      const org = props.org;
      const email = user && user.email;

      Api.requestWithSession({
        url: "auth/users/password/policy",
        params: {
          oid: org.id,
        },
      })
        .then(resp => {
          setPasswordPolicyInfo({
            email: email || "",
            passwordPolicyText: resp.display_text || "",
            passwordPolicy: resp.password_policy || [],
          });
          setStatusInfo({ status: undefined, message: undefined, loading: false });
        })
        .catch(() => {
          setPasswordPolicyInfo({
            email: "",
            passwordPolicyText: "",
            passwordPolicy: [],
          });
          setStatusInfo({ status: undefined, message: undefined, loading: false });
        });
    } else {
      Api.get({
        url: "auth/registrations/password/policy",
        params: {
          reset_password_token: props.token,
          app_key: AppsConfig.getAppKeyFor(app),
        },
      })
        .then(resp => {
          setPasswordPolicyInfo({
            email: resp.email,
            passwordPolicyText: resp.display_text,
            passwordPolicy: resp.password_policy || [],
          });
          setStatusInfo({ status: undefined, message: undefined, loading: false });
        })
        .catch(() => {
          setPasswordPolicyInfo({
            email: "",
            passwordPolicyText: "",
            passwordPolicy: [],
          });
          setStatusInfo({ status: undefined, message: undefined, loading: false });
        });
    }
  }, [app, email, passwordPolicyText, props.org, props.token, props.user, type]);

  React.useEffect(() => {
    OrgSource.fetchOrgWithSlug(props.slug);
  }, [props.slug]);

  const title =
    app === "volunteers" ? "Enter a new password" : type === "change-password" ? "Change Password" : "Forgot Password";
  const forgotPasswordText =
    type === "change-password"
      ? "Please enter your current and new password below:"
      : "You have requested to reset your password.";

  if (type === "change-password" && !SessionStore.getUser()) {
    const to = app === "evertrue" ? `/${app}/enter-email` : `/${app}/login`;
    return <Redirect to={to} />;
  }

  const handleSubmit = (currentPassword, password, confirmation) => {
    setStatusInfo({ status: undefined, message: undefined, loading: true });

    if (type === "change-password") {
      Api.requestWithSession({
        type: "POST",
        url: "auth/users/password/change",
        params: {
          oid: props.org.id,
        },
        data: {
          current_password: currentPassword,
          new_password: password,
          new_password_confirmation: confirmation,
        },
      })
        .then(resp => {
          setStatusInfo({
            status: "success",
            message: "Your password has successfully been reset.",
            loading: false,
          });
          SessionSource.createSessionWithEmail(email, password, props.device_id);
        })
        .catch(({ responseJSON = {}, responseText }) => {
          ErrorLogger.captureRequest("ResetPassword", responseText);

          let errors = responseJSON.errors || {};
          let message = responseJSON.message;

          if (errors.reset_password_token && _.contains(errors.reset_password_token, "is invalid")) {
            message = `Your reset password link has expired.
          Please request a new link below.`;
          }

          // Password reset fails for super users if they don't have a very complicated password
          // so adding more messaging here to indicate that they probably just need to make it more secure
          if (errors.password && _.contains(errors.password, "Password is too weak")) {
            message = `Password reset failed. Our password requirements are based on complexity,
          not specific criteria. Please try again and consider more characters, special characters and numbers,
          and randomization to successfully create a password.`;
          } else if (errors.password && errors.password.length && errors.password.length > 0) {
            message = responseJSON.display_message;
          }

          setStatusInfo({ status: "error", message: message, loading: false });
        });
    } else {
      Api.put({
        url: "auth/registrations/password",
        data: {
          user: {
            password: password,
            reset_password_token: props.token,
          },
        },
      })
        .then(resp => {
          setStatusInfo({
            status: "success",
            message: "Your password has successfully been reset.",
            loading: false,
          });
          SessionSource.createSessionWithEmail(email, password, props.device_id);
        })
        .catch(({ responseJSON = {}, responseText }) => {
          ErrorLogger.captureRequest("ResetPassword", responseText);

          let errors = responseJSON.errors || {};
          let message = responseJSON.message;

          if (errors.reset_password_token && _.contains(errors.reset_password_token, "is invalid")) {
            message = `Your reset password link has expired.
          Please request a new link below.`;
          }

          // Password reset fails for super users if they don't have a very complicated password
          // so adding more messaging here to indicate that they probably just need to make it more secure
          if (errors.password && _.contains(errors.password, "Password is too weak")) {
            message = `Password reset failed. Our password requirements are based on complexity,
          not specific criteria. Please try again and consider more characters, special characters and numbers,
          and randomization to successfully create a password.`;
          } else if (errors.password && errors.password.length > 0) {
            message += " Password must ";
            let errorMessages = errors.password.map(e => {
              return e.replace("must ", "");
            });
            if (errorMessages.length > 1) {
              let lastErrorMessage = errorMessages.pop();
              errorMessages.push(`and ${lastErrorMessage}`);
            }

            if (errorMessages.length > 2) {
              message += errorMessages.join(", ");
            } else {
              message += errorMessages.join(" ");
            }
            message += ".";
          }

          setStatusInfo({ status: "error", message: message, loading: false });
        });
    }
  };

  const renderAppLink = login_text => {
    // "community" is currently the default app when there's a url to /reset_password
    // So the user clicked on a url with /reset_password from volunteers and now it's community/account/password/reset
    // we don't want to redirect to community
    // TODO - update all /reset_password links to have the correct app in them
    if (app !== "community") {
      return (
        <Link to={`/${app}/login/`}>
          {app !== "volunteers" ? (
            <Button className="forgot-password--return"> {login_text} </Button>
          ) : (
            <button> {login_text} </button>
          )}
        </Link>
      );
    }
    return null;
  };

  if (redirect) {
    const envPrefix = Config.url_environment === "production" || Config.url_environment === "p" ? "" : "stage-";
    window.location.replace(`https://${envPrefix}${AppsConfig.getProp(app, "domain")}.evertrue.com`);
  }

  if (status === "success" && app !== "volunteers" && type === "forgot-password" && !_.isEmpty(SessionStore.getSession())) {
    return (
      <Confirmation
        email={email}
        type={type}
        handleSubmit={() => {
          setRedirect(true);
        }}
      />
    );
  } else if (app !== "volunteers" && type === "forgot-password" && !_.isEmpty(SessionStore.getSession())) {
    const envPrefix = Config.url_environment === "production" || Config.url_environment === "p" ? "" : "stage-";
    window.location.replace(`https://${envPrefix}${AppsConfig.getProp(app, "domain")}.evertrue.com`);
  } else if (status === "success" && app !== "volunteers" && type === "change-password") {
    return (
      <Confirmation
        email={email}
        type={type}
        handleSubmit={() => {
          setRedirect(true);
        }}
      />
    );
  }

  return (
    <div className={`forgot-password is-${app}`}>
      {loading ? <Loading /> : null}

      {status === "success" ? (
        <React.Fragment>
          <FormStatus status={status} message={message} />
          {renderAppLink("Login")}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <FormStatus status={status} message={message} />
          <SetPasswordForm
            onSubmit={handleSubmit}
            app={app}
            passwordPolicy={passwordPolicyInfo.passwordPolicy}
            requireCurrentPassword={type === "change-password"}
            submitButtonText={type === "change-password" ? "Submit" : "Reset Password"}
            header={() => {
              return (
                <React.Fragment>
                  <h2>{title}</h2>
                  <p className="forgot-password--text">{forgotPasswordText}</p>
                  {app !== "volunteers" && email ? (
                    <p className="forgot-password--email-display">
                      Change password for: <strong>{email}</strong>
                    </p>
                  ) : null}
                </React.Fragment>
              );
            }}
          />

          {type !== "change-password" && renderAppLink("Return to Login")}
          {status === "error" && type !== "change-password" && (
            <div className="forgot-password--trouble">
              <strong>Having Trouble?</strong>
              <Link to={`/${app}/account/password/${slug || ""}`}> Click here to request a new link.</Link>
            </div>
          )}
          {app !== "volunteers" && passwordPolicyText && (
            <div class="forgot-password--password-policy-text">
              <strong>Note:</strong> {passwordPolicyText}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ResetPasswordController.propTypes = {
  app: PropTypes.string,
  token: PropTypes.string,
  slug: PropTypes.string,
  type: PropTypes.string,
  device_id: PropTypes.string,
};

export default connect(ResetPasswordController, [SessionStore, OrgStore], mapStateToProps);
