import Cookies from "js-cookie";
import Config from "config/env";

const storeToken = token => {
  const name = `${Config.environment_data.toLowerCase()}_etauth`;
  const date = new Date();
  Cookies.set(name, token, {
    expires: new Date(date.setMinutes(date.getMinutes() + 5)),
    secure: !Config.isDevelopment(),
    domain: "evertrue.com",
  });
};

export default { storeToken };
