import PropTypes from "prop-types";

const propTypes = {
  when: PropTypes.bool,
};

const defaultProps = {
  when: undefined,
};

const Show = ({ when, children }) => {
  if (when) {
    return children;
  }
  return null;
};

Show.displayName = "Show";
Show.propTypes = propTypes;
Show.defaultProps = defaultProps;

export default Show;
