import React from "react";
import PropTypes from "prop-types";
import { Button } from "@evertrue/et-components";
import ErrorLogger from "utils/error-logger";

import SessionSource from "apps/session/session-source";
import ErrorMessage from "./error-message";

const EmailPasswordForm = props => {
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [statusInfo, setStatusInfo] = React.useState({ status: "pending", message: "" });

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        const user_email = props.email ? props.email : email;
        SessionSource.promise
          .createSessionPromiseWithEmail(user_email, password, props.device_id)
          .catch(({ responseJSON = {}, responseText }) => {
            ErrorLogger.captureRequest("LoginController", responseText);
            const message = responseJSON.message || responseJSON.error || "Invalid email and/or password.";
            setStatusInfo({ status: "error", message: message });
          });
      }}
    >
      {props.email ? (
        <div className="fs-exclude-data login--current-email">
          Sign-in as:
          {` `}
          <strong>{props.email}</strong>
        </div>
      ) : (
        <input
          onChange={el => {
            setEmail(el.target.value);
          }}
          type="email"
          placeholder="Email Address"
        />
      )}

      <input
        className="fs-exclude-data"
        onChange={el => {
          setPassword(el.target.value);
        }}
        type="password"
        placeholder="Password (8 or more characters)"
      />
      {statusInfo.status === "error" && <ErrorMessage message={statusInfo.message} />}
      <Button type="secondary" buttonType="submit">
        Log In With Email
      </Button>
    </form>
  );
};

EmailPasswordForm.propTypes = {
  email: PropTypes.string,
};

export default EmailPasswordForm;
