import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { createComponent } from "@evertrue/et-flux";
import { Avatar } from "@evertrue/et-components";
import SessionStore from "apps/session/session-store";
import SessionSource from "apps/session/session-source";
import OrgSource from "apps/org/org-source";
import MobileUtils from "utils/mobile-utils";

const UserCard = ({ user = {}, index, envPrefix, onClick }) => {
  return (
    <div tabIndex={index} role="menuitem" className="user-picker--user-card" onClick={() => onClick(user)}>
      <Avatar
        className="user-picker--user-avatar"
        url={user.user_profile_picture_url || `https://${envPrefix}api.evertrue.com/lids/users/${user.id}/linkedin/avatar`}
      />
      <div className="user-picker--user-details"> {user.email} </div>
    </div>
  );
};

UserCard.propTypes = {
  user: PropTypes.object,
  index: PropTypes.number,
  onClick: PropTypes.func,
};

export default createComponent("UserPicker", {
  propTypes: {
    app: PropTypes.string,
    slug: PropTypes.string,
  },

  registerStores() {
    this.on(SessionStore, () => ({
      users: SessionStore.getUserOptions(),
      refresh_token: SessionStore.getRefreshToken(),
    }));
  },

  handleSelect(user) {
    const device_id = MobileUtils.isMobile() ? MobileUtils.getDeviceIdFromStorage() : undefined;
    SessionSource.createSessionWithLidsToken(user.id, this.state.refresh_token, device_id);
    OrgSource.setFromUser(user);
  },

  render() {
    const { app } = this.props;
    const env = window.location.host.split("-")[0];
    const envPrefix = env === "local" || env === "stage" ? "stage-" : "";

    return (
      <div className={`user-picker is-${app}`}>
        <h2 className={`user-picker--title is-${app}`}>Select a User</h2>

        <div className={`user-picker--cards is-${app}`}>
          {_.map(this.state.users, (user, index) => {
            return (
              <UserCard key={user.id} user={user} index={index} envPrefix={envPrefix} onClick={this.handleSelect} />
            );
          })}
        </div>
      </div>
    );
  },
});
