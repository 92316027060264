import _ from "underscore";
import $ from "jquery";
import { createSource } from "@evertrue/et-flux";
import Config from "config/env";

export default createSource("SsoSource", {
  actions: {
    ssoError(method_name, extra) {
      return this.require(_.isString(method_name), "method_name must be a string");
    },
  },

  promise: {
    fetchAccessToken(sso_code, token_data = {}) {
      const data = {
        grant_type: token_data.grant_type,
        code: sso_code,
        redirect_uri: token_data.redirect_uri,
      };
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token_data.authorization,
      };

      const encoded_data = $.param(data);

      return $.ajax({
        url: token_data.url,
        method: "POST",
        headers,
        data: encoded_data,
        error: xhr => {
          // redirect urls only include stage-accounts and accounts
          if (Config.isDevelopment()) {
            console.log(`https://local-accounts.evertrue.com:3016/evertrue/login/sso?code=${sso_code}`);
          }
          this.actions.ssoError("SsoSource.fetchAccessToken", { xhr, headers, data });
        },
      });
    },
  },

  api: {
    setDeferredError(name, extra) {
      _.defer(() => {
        this.actions.ssoError(name, extra);
      });
    },
  },
});
