import _ from "underscore";
import { createSource } from "@evertrue/et-flux";

export default createSource("UserSource", {
  actions: {
    setUser(user) {
      return this.require(_.isObject(user), "user must be a object");
    },
  },

  api: {
    setCurrentUser(user) {
      return this.actions.setUser(user);
    },
  },
});
