import $ from "jquery";
import _ from "underscore";
import api from "utils/api";
import Config from "config/env";
import MobileUtils from "utils/mobile-utils";

const SCOPED = "SCOPED";

// this file is just for making auth calls
// they are sufficiently different from other calls
// that it's simpler to just do them by hand

const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
  "Authorization-Multifactor": 1,
};

const compactObject = (source = {}) => {
  const destination = {};
  _.each(source, (val, key) => {
    if (val) {
      destination[key] = val;
    }
  });
  return destination;
};

const refreshSession = options => {
  return $.ajax({ type: "GET", ...options });
};

const createScopedSession = (options = {}) => {
  const { url, params, oid, headers, ...rest } = options;

  const requestHeaders = !window.location.pathname.match(/super/i) ? headers : _.omit(headers, "Device-ID");

  const default_url =
    requestHeaders && requestHeaders["Device-ID"] && MobileUtils.isMobile() && !!MobileUtils.getDeviceOS()
      ? "auth/session"
      : "skiff";

  const xhrFields =
    requestHeaders && requestHeaders["Device-ID"] && MobileUtils.isMobile() && !!MobileUtils.getDeviceOS()
      ? {}
      : { withCredentials: true };

  const base_url = url || default_url;

  const scope_oid = oid || api.getOid();
  const app_key = api.getAppKey();

  const params_string = params ? "?" + $.param(compactObject(params)) : "";

  const full_url = Config.apiBase + base_url + params_string;

  return $.ajax({
    url: full_url,
    xhrFields,
    type: "POST",
    data: JSON.stringify({
      type: SCOPED,
      oid: scope_oid,
      app_key,
    }),
    headers: { ...HEADERS, ...compactObject(requestHeaders) },
    ...rest,
  });
};

const AuthApi = { refreshSession, createScopedSession };

if (Config.isDevelopment()) {
  window.EverTrue.AuthApi = AuthApi;
}

export default AuthApi;
