import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
};

const defaultProps = {
  status: undefined,
  message: "",
};

const FormStatus = ({ status, message }) => {
  if (!status) {
    return null;
  }
  return (
    <div className={`form-status is-${status}`} data-testid="form-status--message">
      {" "}
      {message}{" "}
    </div>
  );
};

FormStatus.displayName = "FormStatus";
FormStatus.propTypes = propTypes;
FormStatus.defaultProps = defaultProps;

export default FormStatus;
