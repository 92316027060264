/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { createSource } from "@evertrue/et-flux";

import AppsConfig from "config/apps-config";

export default createSource("AppRedirectSource", {
  actions: {
    clearError: true,
  },

  api: {
    setReferringUrl(app, url) {
      return typeof localStorage.setItem === "function" ? localStorage.setItem(`et-${app}-referrer`, url) : undefined;
    },

    redirectToApp(app, oid) {
      let app_path = AppsConfig.getRoutePath(app);
      const stored_path = localStorage.getItem(`et-${app}-referrer`);

      if (stored_path) {
        app_path = stored_path;
      }

      if (oid) {
        app_path += "?oid=oid";
      }

      window.location = app_path;
    },

    redirectFromError() {
      return this.actions.clearError();
    },
  },
});
