import AppsConfig from "config/apps-config";
import _ from "underscore";
import React from "react";
import { Avatar } from "@evertrue/et-components";
import { Redirect, Link } from "react-router-dom";

const APPS = AppsConfig.getAll();
const noApps = _.isEmpty(APPS); // to manage accounts replacement

const AppPicker = props => {
  const [selected_app, setSelectedApp] = React.useState();

  const renderApps = () => {
    _.map(APPS, (app, index) => {
      return (
        <div
          key={app.key}
          className="app-picker--card"
          role="menuitem"
          tabIndex={index}
          onClick={() => {
            setSelectedApp(app.key);
          }}
        >
          <Avatar className="app-picker--avatar" url={app.image} />
          <div className="app-picker--details">{app.name}</div>
        </div>
      );
    });
  };

  return (
    <div className="app-picker">
      <h2 className="app-picker--title">{noApps ? "Manage Your EverTrue Account" : "Choose an Application"}</h2>

      {selected_app ? (
        <Redirect to={`/${selected_app}`} push="true" />
      ) : (
        <div className="app-picker--cards">
          {noApps ? (
            <Link to="/forgot_password" className="app-picker--card">
              Forgot Password
            </Link>
          ) : (
            renderApps()
          )}
        </div>
      )}
    </div>
  );
};

export default AppPicker;
