import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@evertrue/et-components";

const propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const defaultProps = {
  message: "Oops! There was a problem processing your request.",
};

const ErrorMessage = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <div className="error-message">
      <Icon icon="cancel" className="error-message--icon" />
      <div className="error-message--text"> {message} </div>
    </div>
  );
};

ErrorMessage.displayName = "ErrorMessage";
ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;

export default ErrorMessage;
